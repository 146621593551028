import { ListItemButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../util';

export default function PageTemplateSelectorMenuStateOnly({ token, setToken, open, setOpen, pageTemplates, anchorEl, callback }) {
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    const selectPageTemplate = (pageTemplateId) => {
        if (!token)
            return logOut({ navigate, setToken })

        setOpen(false)
        callback({ pageTemplateId })
    };

    return (
        <Menu
            id="page-template-selector-menu"
            anchorEl={anchorEl}
            open={open && Boolean(anchorEl)}
            onClose={handleClose}
        > {[
            (
                <ListItemButton key="select prompt" tabIndex={0} disabled >Select Landing Page</ListItemButton>
            ),
            (
                <MenuItem
                    key={'page template not selected'}
                    onClick={e => selectPageTemplate(null)}
                    // selected={selectedPageTemplateId === null || selectedPageTemplateId === undefined}
                >
                    None (instant redirect)
                </MenuItem>
            ),
            (pageTemplates && pageTemplates
                .map(pageTemplate =>
                    <MenuItem
                        key={pageTemplate._id}
                        onClick={e => selectPageTemplate(pageTemplate._id)}
                        // selected={selectedPageTemplateId === pageTemplate._id}
                    >
                        {pageTemplate.name}
                    </MenuItem>
                )
            )
        ]}
        </Menu>
    );
}
