import { Fragment, useEffect, useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, ListItem, ListItemIcon, Box } from '@mui/material';
import { Add } from '@mui/icons-material';

export default function AttributeValueSelectorMenu({ open, setOpen, attributes, attributeValues, anchorEl, setAnchorEl, selectedAttribute, setSelectedAttribute, selectedAttributeValues, setSelectedAttributeValues, singleSelect, setCreateDialogOpen }) {

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    };

    // Issue with anchorEl being null after deselecting all attribute values for some attribute
    const handleRemoveFilter = () => {
        setSelectedAttributeValues([])
    };

    const toggleSelectAttributeValue = (value) => {
        if (singleSelect) {
            setSelectedAttributeValues([value])
            return
        }

        setSelectedAttributeValues(() => {

            let alreadySelected = selectedAttributeValues?.find(val => val._id === value._id)
            // deselect if already selected
            if (alreadySelected)
                return selectedAttributeValues.filter(val => val._id !== value._id)
            // add to selected values if not already selected
            if (selectedAttributeValues?.length)
                return [...selectedAttributeValues, value]
            return [value]
        })
    };

    return (
        <Menu
            id="attribute-selector-menu"
            open={open && Boolean(anchorEl) && Boolean(selectedAttribute)}
            anchorEl={anchorEl}
            onClose={handleClose}
        > {[
            (setCreateDialogOpen ?
                <Box key="create new value">
                    <MenuItem onClick={(event) => {
                        setCreateDialogOpen(true)
                        event.preventDefault();
                    }}
                    >
                        <ListItemIcon>
                            <Add fontSize="small" />
                        </ListItemIcon>
                        New {selectedAttribute?.name}
                    </MenuItem>
                    <Divider />
                </Box>
                :
                <Box key="select prompt">
                    <ListItem tabIndex={0} disabled >Select {selectedAttribute?.name}</ListItem>
                    <Divider />
                </Box>
            ),
            (attributeValues && attributeValues
                // only show values of the selected attribute
                .filter(value => value.attributeId === selectedAttribute?._id)
                .map(value =>
                    <MenuItem
                        selected={Boolean(selectedAttributeValues?.find(val => val._id === value._id))}
                        key={value._id}
                        onClick={e => toggleSelectAttributeValue(value)}
                    >
                        {value.name}
                    </MenuItem>
                )
            ),
            ((selectedAttribute && selectedAttributeValues && selectedAttributeValues?.length > 0) ?
                <Box key="remove filter">
                    <Divider />
                    <MenuItem
                        onClick={handleRemoveFilter}
                        sx={{ color: "firebrick" }}
                    >
                        Remove attribute
                    </MenuItem>
                </Box>
                :
                null
            )
        ]}
        </Menu>
    );
}
