import { logOut } from './../util'

export {
    createSavedView,
    updateSavedView,
    deleteSavedView
};

async function createSavedView({ token, setToken, navigate, newSavedView }) {
    if (!token)
        return logOut({ navigate, setToken })

    let status;

    return fetch('/api/saved-views/', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(newSavedView)
    })
        .then(response => {
            status = response.status;
            return response.json();
        })
        .then(json => {
            if (status !== 200) {
                if (status === 401) {
                    logOut({ navigate, setToken })
                }
                console.log(json.message);
                return false;
            }
            return json
        })
        .catch(err => {
            console.log(err)
            return false;
        })
}

async function updateSavedView({ token, setToken, navigate, updatedSavedView }) {
    if (!token)
        return logOut({ navigate, setToken })

    let status;

    fetch('/api/saved-views/' + updatedSavedView._id, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(updatedSavedView)
    })
        .then(response => {
            status = response.status;
            return response.json();
        })
        .then(json => {
            if (status !== 200) {
                if (status === 401) {
                    logOut({ navigate, setToken })
                }
                console.log(json.message);
                return false;
            }
            return json
        })
        .catch(err => {
            console.log(err)
            return false;
        })
}

async function deleteSavedView({ token, setToken, navigate, id }) {
    if (!token)
        return logOut({ navigate, setToken })

    let status;

    return fetch('/api/saved-views/' + id, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
        .then(response => {
            status = response.status;
            if (status !== 200) {
                if (status === 401) {
                    logOut({ navigate, setToken })
                }
                console.log(response);
                return false;
            }
            return true
        })
        .catch(err => {
            console.log(err)
            return false;
        })
}