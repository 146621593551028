import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Title from './../text/title';
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import ScanFilters from "./scan-filters";

dayjs().format()
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

// Reducer to update filters in blocks in savedViews. Probably should be moved upstream to analytics page.
function setFilter({ blockId, selectedSavedViewId, setState }) {
    return function (updateFunction) {
        setState(views => {
            let newViews = JSON.parse(JSON.stringify(views))
            let block = newViews.find(v => v._id === selectedSavedViewId)?.blocks.find(b => b._id === blockId)
            if (block) {
                let newFilter = updateFunction(block.filter??{})
                block.filter = newFilter
            }
            return newViews
        })
    }
}

export default function ReturnRate({ token, setToken, id, title, filter, setState, selectedSavedViewId, readOnly }) {
    const [data, setData] = useState(false);
    // const [filter, setFilter] = useState(filter??{});
    let navigate = useNavigate()

    useEffect(() => {
        let status;
        let sevenDaysAgo = dayjs().startOf('d').subtract(6, 'd').valueOf();

        fetch('/api/return-rate?'
            // + new URLSearchParams({
            //     firstVisitStart: sevenDaysAgo
            // })
            ,
            {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify(filter)
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }
                setData(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [filter])

    return (
        <React.Fragment>
            {title &&
                <Title>{title}</Title>
            }
            <ScanFilters
                token={token}
                filter={filter}
                setFilter={setFilter({ blockId: id, selectedSavedViewId, setState })}
                readOnly={readOnly}
            />
            <Typography component="p" variant="h4">
                {data ?
                    (data.newGuestCount > 0) ?
                        (data.returnRate * 100).toFixed(1) + "%"
                        : "No visits"
                    : "Loading"}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
                {data ?
                    (data.newGuestCount > 0) ?
                        `${data.newReturnerCount} of ${data.newGuestCount} first-time guests returned`
                        :
                        "Try removing some filters"
                    :
                    "Loading"
                }
            </Typography>
            {/* <div>
                <Link color="primary" to={linkPath} component={NavLink} >
                    {linkText}
                </Link>
            </div> */}
        </React.Fragment>
    );
}