import React, { useState, useEffect } from "react";
import CanvasV2 from "./canvasV2";
import Canvas from "./basic-canvas";
import { logOut } from './../util'
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export default function LiveGuestMap({ token, setToken, mapData, setMapData, setSelectedQr }) {
    const [savedViews, setSavedViews] = useState([])

    const canvasWidth = window.innerWidth
    const canvasHeight = window.innerHeight

    let navigate = useNavigate()

    useEffect(() => {
        let ignore = false;
        if (!token)
            return logOut({ navigate, setToken })

        let status;
        fetch('/api/saved-views?' + new URLSearchParams({ viewType: 'tableMap' }),
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(async json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }
                if (json.length > 0 && !ignore) {
                    setSavedViews(json)
                    // Create new savedView if there aren't any already
                }
            })
            .catch(err => {
                console.log(err)
            })

        return () => {
            ignore = true;
        };
    }, [])


    return (
        <React.Fragment>
            {mapData &&
                <CanvasV2 canvasWidth={canvasWidth} canvasHeight={canvasHeight} savedViews={savedViews} setSavedViews={setSavedViews} token={token} setToken={setToken} mapData={mapData} setMapData={setMapData} setSelectedQr={setSelectedQr} ></CanvasV2>
                // : <Box sx={{ m:1 }}>Loading...</Box>
            }
            {/* <Canvas canvasWidth={500} canvasHeight={250} ></Canvas> */}
        </React.Fragment>
    )
}