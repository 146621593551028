import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import { Checkbox, FormControlLabel } from '@mui/material';

export default function CreateQrCodeDialog({ token, setToken, open, setOpen, setQrData }) {
  const [nameInput, setNameInput] = useState('')
  const [createAnother, setCreateAnother] = useState(false)
  let navigate = useNavigate()

  function handleClose() {
    setOpen(false)
    setNameInput('')
  }

  const handleInputChange = (event) => {
    setNameInput(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter')
      handleSubmit()
  }

  function handleSubmit() {
    let status;
    fetch('/api/qr-codes', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({ name: nameInput })
    })
      .then(response => {
        status = response.status;
        return response.json();
      })
      .then(json => {
        if (status !== 200) {
          if (status === 401) {
            logOut({ navigate, setToken })
          }
          if (json.message.includes('same name'))
            alert("Oops! You can't have two QR codes with exactly the same name.")

          console.log(json.message);
          throw new Error("Request to create QR code failed")
        }
        setQrData(qrs => [...qrs, json]);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setNameInput('')
        if (!createAnother) {
          handleClose()
        }
      })
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth >
      <DialogTitle>Create QR Code</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          Create a QR code for each table
        </DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          value={nameInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder='Table 7'
          autoComplete='off'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          label="Create another"
          // sx={{ mb: 2 }}
          control={
            <Checkbox
              checked={createAnother}
              onChange={() => setCreateAnother(val => !val)}
              inputProps={{ 'aria-label': 'Create another' }}
            />
          }
        />
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}
