import { useEffect, useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function AttributeSelectorMenu({ open, setOpen, attributes, anchorEl, setSelectedAttribute, selectedAttributeIds, setSelectedAttributeValues }) {

    const handleClose = () => {
        setOpen(false);
    };

    const selectAttribute = (attribute) => {
        setSelectedAttribute(attribute)
        setSelectedAttributeValues(null)
        handleClose()
    };

    return (
        <Menu
            id="attribute-selector-menu"
            anchorEl={anchorEl}
            open={open && Boolean(anchorEl)}
            onClose={handleClose}
        > {[
            (attributes && attributes
                .filter(attr =>
                    // true
                    // don't show attributes that have already been selected
                    selectedAttributeIds && !selectedAttributeIds.find(a => a === attr._id)
                )
                .map(attribute =>
                    <MenuItem
                        key={attribute._id}
                        onClick={e => selectAttribute(attribute)}
                    >
                        {attribute.name}
                    </MenuItem>
                )
            ),
            (attributes && attributes.length > 0 && selectedAttributeIds.length === attributes?.length &&
                <MenuItem key="no more" disabled>No more attributes to add</MenuItem>
            )
        ]}
        </Menu>
    );
}
