import React, { useState, useEffect } from "react";
import Title from "./../text/title";
import { Button, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, List, Stack } from "@mui/material";
import { Download, MoreHoriz, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut, roundIfInt, downloadObjectAsCSV } from "../util";
import ScanFilters from "./scan-filters";

export default function Guests({ token, setToken, guests, filter, setFilter }) {
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);

    let navigate = useNavigate()

    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data)
            setAnchorData(data);
    };

    const downloadExport = (filter) => {
        let status;
        fetch('/api/guests-export', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(filter)
        })
            .then(response => {
                status = response.status;
                if(status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }
                return response.text()
            })
            .then(text => {
                downloadObjectAsCSV(text, `fido-guest-export ${new Date().toLocaleString()}`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <React.Fragment>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <Title>Guests</Title>
                <Button startIcon={<Download />} onClick={() => downloadExport(filter)} >Export CSV</Button>
            </Stack>
            <ScanFilters
                token={token}
                filter={filter}
                setFilter={setFilter}
            />
            <List>
                {guests?.map(guest =>
                    <ListItem
                        key={guest._id}
                        onMouseOver={() => setHoveredItemId(guest._id)}
                        onMouseOut={() => setHoveredItemId(null)}
                        secondaryAction={
                            <IconButton
                                onClick={e => handleContextMenuClick(e, guest)}
                                sx={{ display: (hoveredItemId === guest._id) ? '' : 'none' }}
                                edge="end"
                                aria-label="more options"
                                value={JSON.stringify(guest)}
                            >
                                <MoreHoriz />
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            variant='rounded'
                        // selected={selectedQrCodeId === item._id}
                        // onClick={e => setSelectedQrCodeId(item._id)}
                        >
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <ListItemText
                                primary={(guest.firstName && guest.lastName) ? `${guest.firstName} ${guest.lastName} - ${guest.emails[0]}` : guest.emails[0]}
                                // TO DO: Refactor this secondary text template to be DRY
                                secondary={guest.avgSpend ? `${guest.visitCount} ${(guest.visitCount > 1) ? 'visits' : 'visit' }, avg. spend $${roundIfInt(guest.avgSpend)}` : `${guest.visitCount} ${(guest.visitCount > 1) ? 'visits' : 'visit' }`}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </React.Fragment>
    )
}