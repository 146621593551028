import React, { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ListItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'

export default function QrAttributeSelectorMenuStateOnly({ token, setToken, open, setOpen, attributes, attributeValues, anchorEl, setAnchorEl, callback }) {
    const [selectedAttribute, setSelectedAttribute] = useState(null)
    let navigate = useNavigate()

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedAttribute(null)
        setOpen(false)
    };

    const selectAttribute = (attribute) => {
        setSelectedAttribute(attribute)
    };

    const selectAttributeValue = (value) => {
        if (!token)
            return logOut({ navigate, setToken })

        setSelectedAttribute(null)
        setOpen(false)
        callback({
            attributeId: selectedAttribute._id,
            valueId: value._id
        })
    };

    const removeAttribute = (e, selectedAttribute) => {
        if (!token)
            return logOut({ navigate, setToken })

        setOpen(false)
        callback({
            attributeId: selectedAttribute._id,
            valueId: null
        })
    };

    return (
        <Menu
            id="attribute-selector-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && open}
            onClose={handleClose}
        > {[
            (!selectedAttribute && attributes &&
                attributes.map(attribute =>
                    <MenuItem
                        key={attribute._id}
                        onClick={e => selectAttribute(attribute)}
                    >
                        {attribute.name}
                    </MenuItem>
                )
            ),
            (selectedAttribute &&
                <ListItem key="select prompt" tabIndex={0} disabled >Select {selectedAttribute.name}</ListItem>
            ),
            (selectedAttribute && attributeValues
                // only show values of the selected attribute
                .filter(value => value.attributeId === selectedAttribute._id)
                .map(value =>
                    <MenuItem
                        key={value._id}
                        onClick={e => selectAttributeValue(value)}
                    // selected={qrCode.attributes?.[selectedAttribute._id] === value._id}
                    >
                        {value.name}
                    </MenuItem>
                )
            ),
            (selectedAttribute && attributes &&
                <MenuItem
                    key="remove attribute"
                    onClick={e => removeAttribute(e, selectedAttribute)}
                    sx={{ color: "firebrick" }}
                >
                    Remove attribute
                </MenuItem>
            ),
        ]}
        </Menu>
    );
}
