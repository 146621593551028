import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink, Navigate, useSearchParams } from "react-router-dom";
import { Login, Start, People } from '@mui/icons-material';

export default function AcceptInvite({ token, setToken }) {
    let [searchParams, setSearchParams] = useSearchParams();
    let key = searchParams.get('key')
    let email = searchParams.get('email')

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let status;
        console.log(key)

        return fetch('/api/accept-invite', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstName: data.get('firstName'),
                lastName: data.get('lastName'),
                password: data.get('password'),
                key
            })
        })
            .then(response => {
                status = response.status;
                if (status !== 200) {
                    alert('Oops - this invite expired. Please ask your Fido admin to send you a new invite.');
                    throw new Error('invite expired')
                }
                return response.json();
            })
            .then(json => {
                
                console.log(json)

                setToken(json.token)
            })
            .catch(err => {
                console.log(err)
            })
    };

    if (token) {
        return <Navigate to="/" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <People />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Join Your Team
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password (at least 10 characters)"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Accept Invite
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}