import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logOut, roundIfInt } from '../util';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Label, Tooltip } from 'recharts';
import Title from '../text/title';


export default function ReturnRateLoyaltyComparisonChart({ token, setToken, attributes, hideTitle }) {
    const [data, setData] = useState(false);
    let navigate = useNavigate()
    const theme = useTheme();

    useEffect(() => {
        let status;

        fetch('/api/return-rate?'
            + new URLSearchParams({
                compareLoyalty: true
            })
            ,
            {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                // body: JSON.stringify(filter)
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                let data = [
                    {
                        name: 'Loyalty Members',
                        "Return Rate": roundIfInt(json.loyaltyMemberReturnRate * 100, 1)
                    },
                    {
                        name: 'Non-loyalty Guests',
                        "Return Rate": roundIfInt(json.nonLoyaltyMemberReturnRate * 100, 1)
                    },
                ]
                setData(data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <React.Fragment>
            {hideTitle ?
                null :
                <Title>Loyalty vs. Non-Loyalty Return Rate</Title>
            }
            <ResponsiveContainer height={hideTitle ? '100%' : '85%'}>
                <BarChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="name"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                        domain={['No scans with this attribute yet']}
                    />
                    <YAxis
                        // stroke={theme.palette.text.secondary}
                        // style={theme.typography.body2}
                        // tickFormatter={tick => `${tick}`}
                        // domain={[0, dataMax => {
                        //     console.log(dataMax)
                        //     return dataMax
                        // }]}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.secondary,
                                ...theme.typography.body1,
                            }}
                        >
                            Return Rate (%)
                        </Label>
                    </YAxis>
                    <Tooltip contentStyle={{backgroundColor: theme.palette.background.paper}} />
                    <Bar
                        isAnimationActive={true}
                        // type="monotone"
                        dataKey="Return Rate"
                        // stroke={theme.palette.primary.main}
                        fill={theme.palette.primary.main}
                    />
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}