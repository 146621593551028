import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'

export default function InviteUserDialog({ token, setToken, open, setOpen }) {
    const [emailInput, setEmailInput] = useState('')
    let navigate = useNavigate()

    function handleClose() {
        setOpen(false)
    }

    const handleInputChange = (event) => {
        setEmailInput(event.target.value);
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter')
            handleSubmit()
    }

    function handleSubmit() {
        let status;
        fetch('/api/invite-user', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
                email: emailInput,
                role: "admin"
            })
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if(status === 401) {
                        logOut({ navigate, setToken })
                    }
                    alert(json.message)
                    return console.log(json.message);
                }
                setOpen(false)
                setEmailInput('')
                alert(`Invite sent to ${emailInput}. It may take a few minutes to show up.`)
            })
            .catch(err => {
                console.log(err)
                setOpen(false)
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth >
            <DialogTitle>Invite Teammate</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Teammates who you invite will be able to do everything that you can do.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder='grady@fidobrewing.com'
                    autoComplete='off'
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Send Invite</Button>
            </DialogActions>
        </Dialog>
    );
}
