import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ResponsiveDrawer from "./responsive-drawer";
import Box from '@mui/material/Box';

import Login from './landers/sign-in'
import SignUp from './landers/sign-up'
import GuestMap from "./pages/guest-map";
import AcceptInvite from "./landers/accept-invite";
import Analytics from "./pages/analytics";
import QrCodesV2 from "./pages/qr-codes-v2";
import AttributesV2 from "./pages/attributes-v2";
import SchedulesV2 from "./pages/schedules-v2";
import GuestsV2 from "./pages/guests-v2";
import Scans from "./pages/scans";


function App() {

  let [token, setToken] = useState(localStorage.getItem('token') || null);

  useEffect(() => {
    if (!token)
      return
    // logOut({ navigate, setToken })

    async function fetchAccount() {

      try {
        let accountResponse = await fetch('/api/account', {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        })

        let data = await accountResponse.json()

        window.clarity("set", "Account Id", data._id)
        window.clarity("set", "Account Name", data.name)

        let url = window.location.href


        if (url.includes('dev.app.fidomap')) {
          window.clarity("set", "Environment", "Dev")
        } else if (url.includes('app.fidomap')) {
          window.clarity("set", "Environment", "Production")
        } else {
          window.clarity("set", "Environment", "Local")
        }

      } catch (err) {
        console.log(err)
      }
    }

    fetchAccount();
  }, [token])

  return (
    <Routes>
      <Route path="/sign-up" element={<SignUp token={token} setToken={setToken} />} />
      <Route path="/sign-in" element={<Login token={token} setToken={setToken} />} />
      <Route path="/accept-invite" element={<AcceptInvite token={token} setToken={setToken} />} />
      <Route path="/*" element={
        <Box sx={{ display: 'flex' }}>
          <ResponsiveDrawer setToken={setToken} token={token} />
          <Routes>
            <Route path="/" element={<Home token={token} />} />
            <Route path="/guest-map" element={<GuestMap token={token} setToken={setToken} />} />
            <Route path="/scans" element={<Scans token={token} setToken={setToken} />} />
            <Route path="/analytics" element={<Analytics token={token} setToken={setToken} />} />
            <Route path="/guests" element={<GuestsV2 token={token} setToken={setToken} />} />
            <Route path="/qr-codes" element={<QrCodesV2 token={token} setToken={setToken} />} />
            <Route path="/attributes" element={<AttributesV2 token={token} setToken={setToken} />} />
            <Route path="/schedules" element={<SchedulesV2 token={token} setToken={setToken} />} />
          </Routes>
        </Box>
      } />
    </Routes>
  );
}

function Home({ token }) {
  return (
    token ?
      <Navigate to="/guest-map" replace />
      :
      <Navigate to="/sign-in" replace />
  );
}

export default App;
