import React, { useState, useEffect, Suspense, useRef } from "react";
import Title from "../text/title";
import { Box, Button, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, List, Stack, Checkbox, Tooltip, Grow, Fade } from "@mui/material";
import { Add, Delete, Aod, Forward, LocalOffer, MoreHoriz } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import CreateQrCodeDialog from "../dialogs/createQrCodeDialog";
import QrCodeMenu from "../menus/qr-code-menu";
import BulkEditRedirectUrlDialog from "../dialogs/bulk-edit-redirect-url-dialog";
import QrAttributeSelectorMenuStateOnly from "../menus/qr-attribute-selector-state-only";
import PageTemplateSelectorMenuStateOnly from "../menus/page-template-selector-state-only";

const QrGenerator = React.lazy(() => import('./../qr-codes/qr-generator'));

export default function QrCodesList({ token, setToken, qrCodes, setQrCodes, pageTemplates, attributes, attributeValues, selectedQrCodeId, setSelectedQrCodeId }) {
    const [pdfQrTitle, setPdfQrTitle] = useState(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    // bulk change menu state
    const [selectedQrIds, setSelectedQrIds] = useState([]);
    const [bulkPageTemplateSelectorOpen, setBulkPageTemplateSelectorOpen] = useState(false);
    const [bulkRedirectDialogOpen, setBulkRedirectDialogOpen] = useState(false);
    const [attributeBulkSelectorOpen, setAttributeBulkSelectorOpen] = useState(false);
    let lastItemClicked = useRef(null)
    let navigate = useNavigate

    useEffect(() => {
        if (!token)
            return logOut({ navigate, setToken })

        async function fetchQrsAndAccount() {
            if (!token)
                return logOut({ navigate, setToken })

            let qrCodesPromise = fetch('/api/qr-codes', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            let accountPromise = fetch('/api/account', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            try {
                let [qrCodesResponse, accountResponse] = await Promise.all([qrCodesPromise, accountPromise]);
                if (qrCodesResponse.status !== 200 || accountResponse.status !== 200) {
                    if (qrCodesResponse.status === 401 || accountResponse.status === 401) {
                        logOut({ navigate, setToken })
                    }
                }
                let [qrCodesJson, accountJson] = await Promise.all([qrCodesResponse.json(), accountResponse.json()]);

                setQrCodes(qrCodesJson);
                setSelectedQrCodeId(qrCodesJson[0]?._id)
                setPdfQrTitle(accountJson.pdfQrTitle)

            } catch (err) {
                console.log(err)
            }
        }

        fetchQrsAndAccount();
    }, [token])

    const bulkUpdateQrAttribute = ({ attributeId, valueId }) => {
        let status;

        let body = {
            attributes: {
                [attributeId]: valueId
            },
            accountId: qrCodes[0]?.accountId,
            ids: selectedQrIds
        }

        fetch('/api/qr-codes', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {

                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    return alert(json.message)
                }

                setQrCodes(state => {
                    let newState = JSON.parse(JSON.stringify(state))
                    let currentlyViewing = newState.find(i => i._id === selectedQrCodeId)

                    let newAttributes = { ...currentlyViewing.attributes }

                    if (valueId === null) {
                        delete newAttributes[attributeId]
                    } else {
                        newAttributes[attributeId] = valueId
                    }

                    currentlyViewing.attributes = newAttributes

                    return newState
                });
            })
            .catch(err => {
                console.log(err)
            })
    }

    const bulkUpdateQrPageTemplate = ({ pageTemplateId }) => {
        let status;

        let body = {
            pageTemplateId,
            accountId: qrCodes[0]?.accountId,
            ids: selectedQrIds
        }

        fetch('/api/qr-codes', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {

                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    return alert(json.message)
                }

                setQrCodes(state => {
                    let newState = JSON.parse(JSON.stringify(state))
                    let currentlyViewing = newState.find(i => i._id === selectedQrCodeId)

                    currentlyViewing.pageTemplateId = pageTemplateId

                    return newState
                });
            })
            .catch(err => {
                console.log(err)
            })
    }


    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data) {
            setAnchorData(data);
            setContextMenuOpen(true)
        }
    };

    const handleListItemClick = ({ event, qrCodeId, multiselect }) => {
        if (event.shiftKey) {
            event.preventDefault();
            event.stopPropagation()
            setSelectedQrIds(multiSelectTo(qrCodes, selectedQrIds, qrCodeId))
        } else if (event.metaKey || multiselect) {
            event.preventDefault();
            event.stopPropagation()
            setSelectedQrIds(toggleSelected(selectedQrIds, qrCodeId));
        } else {
            setSelectedQrCodeId(qrCodeId)
        }
        lastItemClicked.current = qrCodeId
    }

    const handleSelectAllCheckbox = () => {
        if (selectedQrIds.length == 0) {
            return setSelectedQrIds(qrCodes.map(code => code._id))
        }
        setSelectedQrIds([])
    }

    const toggleSelected = (selectedQrIds, id) => {
        if (selectedQrIds.indexOf(id) === -1) {
            return [...selectedQrIds, id];
        } else {
            return selectedQrIds.filter(i => i !== id);
        }
    }

    const multiSelectTo = (qrCodes, selectedQrIds, id) => {
        const indexOfNew = qrCodes.findIndex(l => l._id === id);
        if (!selectedQrIds.length) {
            return [id];
        }
        const lastSelectedId = lastItemClicked.current
        const indexOfLast = qrCodes.findIndex(qr => qr._id === lastSelectedId);

        const addingToSelected = !selectedQrIds.includes(id)

        if (indexOfNew === indexOfLast) {
            if (addingToSelected)
                return [...selectedQrIds, id];
            return selectedQrIds.filter(qrId => qrId !== id)
        }

        const isSelectingForwards = indexOfNew > indexOfLast;
        const start = isSelectingForwards ? indexOfLast : indexOfNew;
        const end = isSelectingForwards ? indexOfNew : indexOfLast;

        const inBetween = qrCodes.slice(start, end + 1).map(i => i._id);

        // everything in between needs to have its selection toggled.

        if (addingToSelected) {
            const toAdd = inBetween.filter(qrId => {
                // if already selected then no need to select it again
                if (selectedQrIds.includes(qrId)) {
                    return false;
                }
                return true;
            });

            const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
            const combined = [...selectedQrIds, ...sorted];

            return combined;

        } else {
            return selectedQrIds.filter(i => !inBetween.includes(i))
        }
    }

    const handleOpenBulkPageTemplateDialog = (event) => {
        setAnchorEl(event.currentTarget);
        setBulkPageTemplateSelectorOpen(true)
    }

    const handleOpenBulkRedirectUrlDialog = (e) => {
        setBulkRedirectDialogOpen(true)
    }

    const openAttributeSelectorMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setAttributeBulkSelectorOpen(true)
    };

    return (
        <React.Fragment>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <Title>QR Codes</Title>
                <Suspense fallback={null}>
                    {qrCodes ?
                        <QrGenerator qrCodes={qrCodes} pdfQrTitle={pdfQrTitle}></QrGenerator>
                        : null
                    }
                </Suspense>
            </Stack>
            <List dense >
                <ListItem
                    key={'bulk change controls'}
                >
                    <ListItemIcon>
                        <Tooltip title="Select/Deselect All">
                            <Checkbox
                                edge="start"
                                checked={qrCodes?.length === selectedQrIds.length && qrCodes?.length > 0}
                                indeterminate={qrCodes?.length !== selectedQrIds?.length && selectedQrIds?.length > 0}
                                onChange={handleSelectAllCheckbox}
                            />
                        </Tooltip>
                    </ListItemIcon>
                    <Grow in={selectedQrIds.length > 0}>
                        <ListItemIcon>
                            <Tooltip title="Set Landing Page">
                                <IconButton onClick={handleOpenBulkPageTemplateDialog} >
                                    <Aod />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                    </Grow>
                    <PageTemplateSelectorMenuStateOnly
                        token={token}
                        setToken={setToken}
                        pageTemplates={pageTemplates}
                        open={bulkPageTemplateSelectorOpen}
                        setOpen={setBulkPageTemplateSelectorOpen}
                        anchorEl={anchorEl}
                        callback={bulkUpdateQrPageTemplate}
                    />
                    <Grow in={selectedQrIds.length > 0} style={{ transitionDelay: '40ms' }}>
                        <ListItemIcon>
                            <Tooltip title="Set Redirect URL">
                                <IconButton onClick={handleOpenBulkRedirectUrlDialog} >
                                    <Forward />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                    </Grow>
                    <BulkEditRedirectUrlDialog
                        token={token}
                        setToken={setToken}
                        open={bulkRedirectDialogOpen}
                        setOpen={setBulkRedirectDialogOpen}
                        handleCloseExternal={e => e}
                        apiEndpoint={'/api/qr-codes'}
                        update={{ redirectUrl: null }}
                        setState={setQrCodes}
                        dialogTitle={`Set Redirect URL for ${selectedQrIds.length} QR Code${selectedQrIds.length > 1 ? 's' : ''}`}
                        dbFieldname={"redirectUrl"}
                        inputLabel={"Redirect URL"}
                        entityIds={selectedQrIds}
                        accountId={qrCodes?.[0]?.accountId}
                    />
                    <Grow in={selectedQrIds.length > 0} style={{ transitionDelay: '80ms' }}>
                        <ListItemIcon>
                            <Tooltip title="Set Attribute">
                                <IconButton onClick={openAttributeSelectorMenu} >
                                    <LocalOffer />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                    </Grow>
                    <QrAttributeSelectorMenuStateOnly
                        token={token}
                        setToken={setToken}
                        attributes={attributes}
                        attributeValues={attributeValues}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        open={attributeBulkSelectorOpen}
                        setOpen={setAttributeBulkSelectorOpen}
                        callback={bulkUpdateQrAttribute}
                    />
                    {/* <ListItemIcon>
                                <Tooltip title="Delete QR Codes">
                                    <IconButton>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </ListItemIcon> */}
                </ListItem>
                {qrCodes?.map(item =>
                    <ListItem
                        key={item._id}
                        onMouseOver={() => setHoveredItemId(item._id)}
                        onMouseOut={() => setHoveredItemId(null)}
                        secondaryAction={
                            <IconButton
                                onClick={e => handleContextMenuClick(e, item)}
                                sx={{ display: (hoveredItemId === item._id || anchorData?._id === item._id) ? '' : 'none' }}
                                edge="end"
                                aria-label="more options"
                                value={JSON.stringify(item)}
                            >
                                <MoreHoriz />
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            variant='rounded'
                            selected={selectedQrCodeId === item._id}
                            onClick={e => handleListItemClick({ event: e, qrCodeId: item._id, multiselect: false })}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedQrIds.indexOf(item._id) !== -1}
                                    // tabIndex={-1}
                                    // disableRipple
                                    onClick={e => handleListItemClick({ event: e, qrCodeId: item._id, multiselect: true })}
                                    inputProps={{ 'aria-labelledby': item.name }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.name}
                            // secondary={!item.attributes ? "No attributes" : Object.keys(item.attributes).length + " attributes"} 
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <QrCodeMenu token={token} open={contextMenuOpen} setOpen={setContextMenuOpen} setToken={setToken} anchorEl={anchorEl} setAnchorEl={setAnchorEl} anchorData={anchorData} setAnchorData={setAnchorData} setSelectedQrCodeId={setSelectedQrCodeId} setQrData={setQrCodes} />
            {!qrCodes && "Loading"}
            <Box sx={{ mt: 2 }} >
                <Button startIcon={<Add />} onClick={() => setCreateDialogOpen(true)}>
                    Create Qr Code
                </Button>
            </Box>
            <CreateQrCodeDialog token={token} setToken={setToken} open={createDialogOpen} setOpen={setCreateDialogOpen} setQrData={setQrCodes} />
        </React.Fragment>
    )
}