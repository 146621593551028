import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Collapse } from "@mui/material";
import { FilterAlt, LocalOffer } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import AttributeSelectorMenu from "../menus/attribute-selector-menu";
import AttributeValueSelectorMenu from "../menus/attribute-value-selector-menu";
import { useTheme } from "@mui/material";

export default function ScanFilters({ token, setToken, filter, setFilter, singleSelect, readOnly }) {
    const [attributes, setAttributes] = useState([]);
    const [attributeValues, setAttributeValues] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [attributeSelectorOpen, setAttributeSelectorOpen] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedAttributeValues, setSelectedAttributeValues] = useState(null);
    let navigate = useNavigate()
    let theme = useTheme()

    const handleAddFilterClick = (event) => {
        setSelectedAttribute(null)
        setAnchorEl(event.currentTarget);
        setAttributeSelectorOpen(true)
    };

    const handleAttributeFilterClick = (event, attribute) => {
        if (selectedAttributeValues?.length == 0)
            setSelectedAttributeValues(null)
        setSelectedAttribute(attribute)
        setAnchorEl(event.currentTarget);
        setAttributeSelectorOpen(false)
    };

    useEffect(() => {
        async function fetchAttributesAndValues() {
            if (!token)
                return logOut({ navigate, setToken })

            let attributePromise = fetch('/api/attributes', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
            let valuesPromise = fetch('/api/attribute-values', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            try {
                let [attributesResponse, valuesResponse] = await Promise.all([attributePromise, valuesPromise]);
                let [attributesJson, valuesJson] = await Promise.all([attributesResponse.json(), valuesResponse.json()]);
                if (attributesResponse.status !== 200 || valuesResponse.status !== 200) {
                    if (attributesResponse.status === 401 || valuesResponse.status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return alert("attributes: " + attributesJson + "\r\nValues: " + valuesJson);
                }
                setAttributes(attributesJson);
                setAttributeValues(valuesJson);
            } catch (err) {
                console.log(err)
            }
        }
        if (!attributes.length || !attributeValues.length)
            fetchAttributesAndValues();
    }, [])

    const getSelectedAttributeValues = (filter) => {
        if (!selectedAttribute)
            return
        let attribute = filter?.attributes?.find(attr => attr._id === selectedAttribute._id)
        if (attribute?.values.length) {
            let vals = attributeValues?.filter(val => val.attributeId === selectedAttribute._id);
            vals = vals.filter(val => Boolean(attribute.values.find(v => v === val._id)))
            return vals
        }
    }

    // Update filter when new attribute values are selected
    // Seems to be setting values for the attribute that are values of other attributes
    useEffect(() => {
        if (
            !selectedAttribute ||
            selectedAttributeValues === null
        ) {
            return
        }

        if (selectedAttributeValues.length == 0)
            setAnchorEl(null)

        setFilter(filter => {
            let filterCopy = JSON.parse(JSON.stringify(filter))
            if (!filterCopy)
                filterCopy = {}
            if (!filterCopy.attributes)
                filterCopy.attributes = []
            let existingAttribute = filterCopy.attributes?.find(attr => attr._id === selectedAttribute._id)
            let newValues = selectedAttributeValues.map(attr => attr._id)

            if (existingAttribute) {
                // remove attribute altogether if there are no values selected for it
                if (!newValues.length > 0) {
                    filterCopy.attributes = filterCopy.attributes?.filter(attr => {
                        return attr._id !== selectedAttribute._id
                    })
                }
                existingAttribute.values = newValues;
            } else if (newValues.length > 0) {
                filterCopy.attributes.push({
                    _id: selectedAttribute._id,
                    values: newValues
                })
            }
            return filterCopy
        })
    }, [selectedAttributeValues])

    return (
        <React.Fragment>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} >
                {filter?.attributes?.map(attribute => {
                    let a = attributes?.find(attr => attr._id === attribute._id)
                    let values = attributeValues?.filter(val => Boolean(attribute?.values?.find(v => v === val._id)))

                    return (
                        <Button
                            key={attribute._id}
                            disabled={readOnly}
                            sx={{ mr: 1, textAlign: 'left', WebkitTextFillColor: theme.palette.primary.main }}
                            size='small'
                            startIcon={<LocalOffer sx={{ color: theme.palette.primary.main }} />}
                            onClick={e => handleAttributeFilterClick(e, a)}
                        >
                            {a?.name + ':' + values.map(val => val?.name).join(', ')}
                        </Button>
                    )
                }
                )}
                <Collapse in={!readOnly} mountOnEnter unmountOnExit>
                    <Button
                        // sx={{ mr:1 }}
                        size='small'
                        startIcon={<FilterAlt />}
                        onClick={handleAddFilterClick}
                    >
                        Add Filter
                    </Button>
                </Collapse>
                {/* {readOnly ?
                    null :
                    <Button
                        // sx={{ mr:1 }}
                        size='small'
                        startIcon={<FilterAlt />}
                        onClick={handleAddFilterClick}
                    >
                        Add Filter
                    </Button>
                } */}
            </Box>
            {/* <Typography>{JSON.stringify(filter)}</Typography>
            <Typography>{JSON.stringify(selectedAttributeValues)}</Typography> */}
            {attributes && attributeValues &&
                <AttributeSelectorMenu
                    anchorEl={anchorEl}
                    open={attributeSelectorOpen}
                    setOpen={setAttributeSelectorOpen}
                    attributes={attributes}
                    attributeValues={attributeValues}
                    setSelectedAttribute={setSelectedAttribute}
                    setSelectedAttributeValues={setSelectedAttributeValues}
                    selectedAttributeIds={filter?.attributes?.map(attr => attr._id) ?? []}
                />
            }
            {attributes && attributeValues &&
                <AttributeValueSelectorMenu
                    open={Boolean(selectedAttribute)}
                    setOpen={setSelectedAttribute}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    attributes={attributes}
                    attributeValues={attributeValues}
                    selectedAttribute={selectedAttribute}
                    setSelectedAttribute={setSelectedAttribute}
                    selectedAttributeValues={getSelectedAttributeValues(filter)}
                    setSelectedAttributeValues={setSelectedAttributeValues}
                    singleSelect={singleSelect}
                />
            }
        </React.Fragment>
    )
}