exports.mockGuestMapResponse = [
    {
        "qr": {
            "_id": "62fc3e182c0d2cde0b82d34f",
            "name": "Table 1",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1660698136896,
            "updatedAt": 1661988049334,
            "attributes": {
                "6303d36bbe2ad7705eca7061": "6303dc1625f2dc092c322ed0",
                "6305affe10c8258906162539": "6305b00b10c825890616253a"
            },
            "redirectUrl": "https://www.beerbaronbar.com/oakland-qr",
            "x": 436,
            "y": 28,
            "width": 140,
            "height": 100,
            "loyaltyButtonText": "Loyalty Checkin"
        },
        "scans": [
            {
                "_id": "630fe93d0940eb2e195d0cf1",
                "qrName": "Table 41",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "62fc3e182c0d2cde0b82d34f",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 1,
                "createdAt": 1661987133425,
                "updatedAt": null,
                "visitCount": 2
            }
        ]
    },
    {
        "qr": {
            "_id": "6304747478592b3af291af78",
            "name": "Table 2",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661236340332,
            "updatedAt": 1661988054497,
            "attributes": {
                "6303d36bbe2ad7705eca7061": "6303def025f2dc092c322ed5",
                "6305affe10c8258906162539": "6305b00b10c825890616253a"
            },
            "redirectUrl": "https://wsj.com",
            "x": 23,
            "y": 27,
            "width": 140,
            "height": 100
        },
        "scans": [
            {
                "_id": "630fee560940eb2e195d0cf6",
                "qrName": "Table 2",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6304747478592b3af291af78",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 4,
                "createdAt": 1661988438578,
                "updatedAt": null,
                "visitCount": 2
            },
            {
                "_id": "630fee560940eb2e195d0cd3",
                "qrName": "Table 2",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6304747478592b3af291af78",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 4,
                "createdAt": 1661988438578,
                "updatedAt": null,
                "visitCount": 9
            },
            {
                "_id": "630fee560940hb2e195d0cf8",
                "qrName": "Table 2",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6304747478592b3af291af78",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 4,
                "createdAt": 1661988438578,
                "updatedAt": null,
                "visitCount": 9
            },
        ]
    },
    {
        "qr": {
            "_id": "630475af6c441344c3b75cbd",
            "name": "Table 3",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661236655024,
            "updatedAt": 1661988059086,
            "attributes": {
                "6303d36bbe2ad7705eca7061": "6303dc1625f2dc092c322ed0",
                "6305affe10c8258906162539": "6305b00b10c825890616253a"
            },
            "redirectUrl": "https://wsj.com",
            "x": 661,
            "y": 165,
            "width": 100,
            "height": 100
        },
        "scans": [
            {
                "_id": "630fee620940eb2e195d0cf7",
                "qrName": "Table 3",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630475af6c441344c3b75cbd",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 1,
                "createdAt": 1661988450661,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630fee620940eb2e195d0cf9",
                "qrName": "Table 3",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630475af6c441344c3b75cbd",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 1,
                "createdAt": 1661988450661,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630fee620940eb2e195d0cr7",
                "qrName": "Table 3",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630475af6c441344c3b75cbd",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 1,
                "createdAt": 1661988450661,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630fee620940eb2e195d0c27",
                "qrName": "Table 3",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630475af6c441344c3b75cbd",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 2,
                "createdAt": 1661988450661,
                "updatedAt": null,
                "visitCount": 2
            },
        ]
    },
    {
        "qr": {
            "_id": "6305aff010c8258906162538",
            "name": "Table 4",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661317104381,
            "updatedAt": 1661988064576,
            "attributes": {
                "6303d36bbe2ad7705eca7061": "6303d4aebe2ad7705eca7062",
                "6305affe10c8258906162539": "6305b00b10c825890616253a"
            },
            "redirectUrl": "http://localhost:3001/qr-codes",
            "x": 225,
            "y": 28,
            "width": 160,
            "height": 100
        },
        "scans": [
            {
                "_id": "630fee690940eb2e195d0ca8",
                "qrName": "Table 4",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6305aff010c8258906162538",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 6,
                "createdAt": 1661988457572,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630fee690940eb2e195d0cf9",
                "qrName": "Table 4",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6305aff010c8258906162538",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 6,
                "createdAt": 1661988457572,
                "updatedAt": null,
                "visitCount": 3
            },
            {
                "_id": "630fee690940eb2e195d0cf0",
                "qrName": "Table 4",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6305aff010c8258906162538",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 6,
                "createdAt": 1661988457572,
                "updatedAt": null,
                "visitCount": 9
            },
            {
                "_id": "630fee690940eb2e195d0cf8",
                "qrName": "Table 4",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "6305aff010c8258906162538",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 6,
                "createdAt": 1661988457572,
                "updatedAt": null,
                "visitCount": 9
            }
        ]
    },
    {
        "qr": {
            "_id": "630c3b6d7b6df145568a00c2",
            "name": "Table 5",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661746029440,
            "updatedAt": 1661988068749,
            "x": 243,
            "y": 217,
            "width": 100,
            "height": 60,
            "attributes": null,
            "redirectUrl": null
        },
        "scans": [
            {
                "_id": "630fee6f0940eb2e195d0cf9",
                "qrName": "Table 5",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c3b6d7b6df145568a00c2",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 7,
                "createdAt": 1661988463862,
                "updatedAt": null,
                "visitCount": 2
            },
            {
                "_id": "630fee6f0940eb2e195d0cf0",
                "qrName": "Table 5",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c3b6d7b6df145568a00c2",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 7,
                "createdAt": 1661988463862,
                "updatedAt": null,
                "visitCount": 3
            },
        ]
    },
    {
        "qr": {
            "_id": "630c449c4f2771312a250e17",
            "name": "Table 6",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661748380696,
            "updatedAt": 1661988073196,
            "x": 24,
            "y": 186,
            "width": 120,
            "height": 80,
            "attributes": null,
            "redirectUrl": "https://wsj.com"
        },
        "scans": [
            {
                "_id": "630fee740940eb2e195d0cfb",
                "qrName": "Table 6",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c449c4f2771312a250e17",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 8,
                "createdAt": 1661988468988,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630fee740940eb2e195d0cfc",
                "qrName": "Table 6",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c449c4f2771312a250e17",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 8,
                "createdAt": 1661988468988,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630fee740940eb2e195d0cfd",
                "qrName": "Table 6",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c449c4f2771312a250e17",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 8,
                "createdAt": 1661988468988,
                "updatedAt": null,
                "visitCount": 9
            },
        ]
    },
    {
        "qr": {
            "_id": "630c44bf4f2771312a250e1a",
            "name": "Table 7",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661748415102,
            "updatedAt": 1661988077953,
            "x": 22,
            "y": 293,
            "width": 120,
            "height": 80,
            "attributes": null,
            "redirectUrl": "https://wsj.com"
        },
        "scans": [
            {
                "_id": "630fee7c0940eb2e195d0cfb",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 9,
                "createdAt": 1661988476713,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630feea10940eb2e195d0cfc",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 10,
                "createdAt": 1661988513070,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630feeab0940eb2e195d0cfe",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 11,
                "createdAt": 1661988523599,
                "updatedAt": null,
                "visitCount": 9
            },
            {
                "_id": "630feeab0940eb2e195d0cfd",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 11,
                "createdAt": 1661988523599,
                "updatedAt": null,
                "visitCount": 9
            }
        ]
    },
    {
        "qr": {
            "_id": "6318487590cb28832c4815ab",
            "name": "Table 7",
            "accountId": "62fc392f00c595b37b837e94",
            "createdAt": 1661748415102,
            "updatedAt": 1661988077953,
            "x": 22,
            "y": 293,
            "width": 120,
            "height": 80,
            "attributes": null,
            "redirectUrl": "https://wsj.com"
        },
        "scans": [
            {
                "_id": "630fee7c0940eb2e195d0cfb",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 9,
                "createdAt": 1661988476713,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630feea10940eb2e195d0cfc",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 10,
                "createdAt": 1661988513070,
                "updatedAt": null,
                "visitCount": 1
            },
            {
                "_id": "630feeab0940eb2e195d0cfe",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 11,
                "createdAt": 1661988523599,
                "updatedAt": null,
                "visitCount": 9
            },
            {
                "_id": "630feeab0940eb2e195d0cfd",
                "qrName": "Table 7",
                "fingerprintId": "416de7fda209220e90178f4edafc3485",
                "qrId": "630c44bf4f2771312a250e1a",
                "guestId": "630fe93d0940eb2e195d0cf0",
                "visitNumber": 11,
                "createdAt": 1661988523599,
                "updatedAt": null,
                "visitCount": 9
            }
        ]
    }
]

exports.mockGuestResponse = [
    {
        "_id": "630ffd26dc4a721ce2f86d51",
        "loyaltyCount": 3,
        "visitCount": 5,
        "fingerprintIds": [
            "0d192cbb89526b936192afcb24c53a00"
        ],
        "accountId": "62fc392f00c595b37b837e94",
        "createdAt": 1661992230139,
        "emails": [
            "terence63435@gmail.com"
        ],
        "updatedAt": 1662048336433
    },
    {
        "_id": "630fe93d0940eb2e195d0cf1",
        "loyaltyCount": 3,
        "visitCount": 13,
        "fingerprintIds": [
            "416de7fda209220e90178f4edafc3485",
            "171c0bc3d3aff6aaa83ab62e82f8dadd",
            "3ed5e2335b28582c93a4e63347fc51af",
            "9ca7a5fbb952073aba93bb5de87f2960"
        ],
        "accountId": "62fc392f00c595b37b837e94",
        "createdAt": 1661987133323,
        "emails": [
            "sherryjackson@gmail.com"
        ],
        "updatedAt": 1662008901261
    },
    {
        "_id": "630fe93d0940eb2e195d0cf6",
        "loyaltyCount": 3,
        "visitCount": 3,
        "fingerprintIds": [
            "416de7fda209220e90178f4edafc3485",
            "171c0bc3d3aff6aaa83ab62e82f8dadd",
            "3ed5e2335b28582c93a4e63347fc51af",
            "9ca7a5fbb952073aba93bb5de87f2960"
        ],
        "accountId": "62fc392f00c595b37b837e94",
        "createdAt": 1661987133323,
        "emails": [
            "boblovesbeer456@hotmail.com"
        ],
        "updatedAt": 1662008901261
    },
    {
        "_id": "630fe93d0940eb2e195d0cf2",
        "loyaltyCount": 0,
        "visitCount": 17,
        "fingerprintIds": [
            "416de7fda209220e90178f4edafc3485",
            "171c0bc3d3aff6aaa83ab62e82f8dadd",
            "3ed5e2335b28582c93a4e63347fc51af",
            "9ca7a5fbb952073aba93bb5de87f2960"
        ],
        "accountId": "62fc392f00c595b37b837e94",
        "createdAt": 1661987133323,
        "emails": [
            "arsentujian@gmail.com"
        ],
        "updatedAt": 1662008901261
    }
]