import React, { useEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Total from "../cards/total";
import { Toolbar, Container, Paper, Box, Button, Stack, IconButton, Dialog, ListItemButton, DialogContent, List, DialogTitle, Fade } from '@mui/material';
import { logOut } from './../util'
import { useNavigate } from "react-router-dom";
import ReturnRate from "../cards/return-rate";
import { Add, Clear, Close, Done, Edit } from '@mui/icons-material';
import ReturnRateChart from "../cards/return-rate-chart";
import ScanCountChart from '../cards/scan-count-chart';
import ScanCountByAtttributeChart from '../cards/scan-count-by-attribute-chart';
import RegularsCountChart from '../cards/regulars-count-chart';
import ReturnRateLoyaltyComparisonChart from '../cards/return-rate-loyalty-comparison-chart';
import ReturnRateWinnersChart from '../cards/return-rate-winners-chart';
import RevenueWinnersChart from '../cards/revenue-winners-chart';


const defaultBlocks = [
    {
        _id: '5fimfoien5',
        type: 'scan chart'
    },
    {
        _id: 'dfgdfgdfhfg',
        type: 'return rate chart'
    },
    {
        _id: '6489gniun435g',
        type: 'first time scans',
        title: 'First-time Guests',
        // statEndpoint: '/api/stats/scans/count-first-time?'
    },
    {
        _id: '9f8n4kjsng5',
        type: 'total scans',
        title: 'Total Scans',
        // statEndpoint: '/api/stats/scans/count?'
    },
    {
        _id: 'ciu4i3ngysfr',
        type: 'returnRate',
        title: 'Patio',
        filter: {
            // attributes:
            //     [
            //         { _id: "6303d36bbe2ad7705eca7061", values: ["6303d4aebe2ad7705eca7062"] }
            //     ]
        }
    },
    {
        _id: 'fwo5nkjneth46',
        type: 'returnRate',
        title: 'Dining Room',
        filter: {}
    },
    {
        _id: 'some block id',
        type: 'regulars chart',
        title: 'Regulars',
        filter: {}
    },
    {
        _id: 'feiungeo5t',
        type: 'returnRate',
        title: 'Patio',
        filter: {}
    },

]

function generateDefaultLayout(blocks) {

    const realData = [
        {
            i: blocks[0]._id,
            x: 0,
            y: 0,
            w: 16,
            h: 4
        },
        {
            i: blocks[1]._id,
            x: 0,
            y: 4,
            w: 16,
            h: 4
        },
        {
            i: blocks[2]._id,
            x: 16,
            y: 0,
            w: 8,
            h: 4
        },
        {
            i: blocks[3]._id,
            x: 24,
            y: 0,
            w: 8,
            h: 4
        },
        {
            i: blocks[4]._id,
            x: 16,
            y: 4,
            w: 8,
            h: 4
        },
        {
            i: blocks[5]._id,
            x: 24,
            y: 4,
            w: 8,
            h: 4
        },
        {
            i: blocks[6]._id,
            x: 0,
            y: 8,
            w: 16,
            h: 4
        },
        {
            i: blocks[7]._id,
            x: 16,
            y: 4,
            w: 8,
            h: 4
        },
    ]

    return realData.map((item) => {
        return {
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
            i: item.i,
            minW: 6,
            maxW: 48,
            minH: 3,
            maxH: 14,
            ...item
        };
    });
}

const drawerWidth = 240;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function BasicGridLayout({ token, setToken, ...props }) {
    const [savedViews, setSavedViews] = useState(false)
    const [selectedSavedViewId, setSelectedSavedViewId] = useState(null)
    const [attributes, setAttributes] = useState(null)
    const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
    const [editingMode, setEditingMode] = useState(false)
    const [blockEditorModalOpen, setBlockEditorModalOpen] = useState(false)
    let viewBeforeEditing = useRef()
    let navigate = useNavigate()

    useEffect(() => {
        if (!token)
            return logOut({ navigate, setToken })

        let status;

        fetch('/api/attributes', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }

                setAttributes(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [token])

    useEffect(() => {
        let ignore = false;

        if (!token)
            return logOut({ navigate, setToken })

        let status;
        fetch('/api/saved-views?' + new URLSearchParams({ viewType: 'blocks' }),
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(async json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }
                if (json.length > 0 && !ignore) {
                    setSavedViews(json)
                    setSelectedSavedViewId(json[0]._id)
                    // Create new savedView if there aren't any already
                } else if (!ignore) {
                    let defaultView = {
                        name: 'Saved view 1',
                        viewType: "blocks",
                        blocks: defaultBlocks,
                        layouts: { lg: generateDefaultLayout(defaultBlocks) }
                    }
                    let view = await createView(defaultView)

                    if (view) {
                        setSelectedSavedViewId(view._id)
                        setSavedViews([view])
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })

        return () => {
            ignore = true;
        };
    }, [])

    // useEffect(() => {
    //     if (!savedViews)
    //         return
    //     async function update() {
    //         let view = savedViews.find(v => v._id === selectedSavedViewId)
    //         await updateView(view)
    //     }
    //     update()
    // }, [savedViews])

    const renderBlock = (block) => {
        switch (block.type) {
            case 'total scans':
                return <Total
                    token={token}
                    setToken={setToken}
                    title={block.title}
                    subtitle="Last 7 days"
                    linkText={"View live map"}
                    linkPath={"/guest-map"}
                    statEndpoint={'/api/stats/scans/count?'}
                />
            case "first time scans":
                return <Total
                    token={token}
                    setToken={setToken}
                    title={block.title}
                    subtitle="Last 7 days"
                    linkText={"View live map"}
                    linkPath={"/guest-map"}
                    statEndpoint={'/api/stats/scans/count-first-time?'}
                />
            case "total referral revenue":
                return <Total
                    token={token}
                    setToken={setToken}
                    title={block.title}
                    subtitle=""
                    statPrefix="$"
                    statField="referralRevenue"
                    statEndpoint={'/api/stats/referral-revenue?'}
                />
            case "total referral count":
                return <Total
                    token={token}
                    setToken={setToken}
                    title={block.title}
                    subtitle=""
                    statField="referrals"
                    statEndpoint={'/api/stats/referral-revenue?'}
                />
            case 'return rate chart':
                return <ReturnRateChart
                    token={token}
                    setToken={setToken}
                />
            case 'scan chart':
                return <ScanCountChart
                    token={token}
                    setToken={setToken}
                />
            case 'scan by attribute chart':
                return <ScanCountByAtttributeChart
                    token={token}
                    setToken={setToken}
                    attributeId={block.attributeId}
                    attributes={attributes}
                />
            case 'regulars chart':
                return <RegularsCountChart
                    token={token}
                    setToken={setToken}
                />
            case 'returnRate':
                return <ReturnRate
                    token={token}
                    setToken={setToken}
                    id={block._id}
                    selectedSavedViewId={selectedSavedViewId}
                    setState={setSavedViews}
                    filter={block.filter}
                    readOnly={!editingMode}
                />
            case 'return rate loyalty comparison chart':
                return <ReturnRateLoyaltyComparisonChart
                    token={token}
                    setToken={setToken}
                    attributeId={block.attributeId}
                    attributes={attributes}
                />
            case 'return rate winners chart':
                return <ReturnRateWinnersChart
                    token={token}
                    setToken={setToken}
                    attributeId={block.attributeId}
                    attributes={attributes}
                />
            case "revenue winners chart":
                return <RevenueWinnersChart
                    token={token}
                    setToken={setToken}
                    attributeId={block.attributeId}
                    attributes={attributes}
                />
            default:
                return <h5>Oops - something went wrong when rendering this block.</h5>
        }
    }

    const generateDOM = () => {
        if (Boolean(savedViews) === false) {
            return
        }

        let savedViewToUse = savedViews.find(v => v._id === selectedSavedViewId) ?? savedViews[0]
        let layoutToUse = savedViewToUse.layouts[currentBreakpoint] ?? savedViewToUse.layouts.lg
        // console.log("layoutToUse in generateDOM:")
        // console.log(layoutToUse)

        return layoutToUse.map((l) => {
            let block = savedViewToUse.blocks.find(b => b._id === l.i)

            return (
                <div key={l.i} style={{ cursor: editingMode ? 'grab' : 'inherit' }} >
                    <Fade in={editingMode} mountOnEnter unmountOnExit>
                        <IconButton
                            onClick={e => removeBlock({ viewId: selectedSavedViewId, blockId: l.i })}
                            aria-label="remove block" size="small" sx={{ position: 'absolute', top: 2, right: 2 }}
                        >
                            <Clear />
                        </IconButton>
                    </Fade>

                    <Paper
                        sx={{
                            p: 2,
                            width: 1,
                            height: 1,
                            overflow: 'hidden'
                        }}
                    >
                        {renderBlock(block)}
                    </Paper>
                </div>
            );
        });
    };

    const onBreakpointChange = (breakpoint) => {
        setCurrentBreakpoint(breakpoint);
    };

    async function createView(view) {
        // localStorage.setItem("grid-layouts", JSON.stringify(layouts));
        let status;
        return fetch('/api/saved-views/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(view)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    throw new Error('request failed')
                }
                return json
            })
            .catch(err => {
                console.log(err)
            })
    }

    async function updateView(view) {
        if (!view._id)
            return
        // localStorage.setItem("grid-layouts", JSON.stringify(layouts));
        let status;
        fetch('/api/saved-views/' + view._id, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({ update: view })
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                if (json) {
                    setSavedViews(views => {
                        let newViews = views.filter(v => v._id !== json._id)
                        return [...newViews, json]
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onLayoutChange = async (layout, allLayouts) => {
        let savedViewsCopy = JSON.parse(JSON.stringify(savedViews))
        let currentView = savedViewsCopy.find(v => v._id === selectedSavedViewId)

        if (currentView) {
            currentView.layouts = allLayouts
            setSavedViews(savedViewsCopy)
        }
    };

    const handleStartEditing = () => {
        setEditingMode(true)
        let view = savedViews.find(v => v._id === selectedSavedViewId)
        viewBeforeEditing.current = view
    }

    const handleCancelEditing = () => {
        let savedViewsCopy = JSON.parse(JSON.stringify(savedViews))
        let index = savedViewsCopy.findIndex(v => v._id === selectedSavedViewId)
        savedViewsCopy[index] = viewBeforeEditing.current
        setSavedViews(savedViewsCopy)
        setEditingMode(false)
    }

    const handleDoneEditing = async () => {
        setEditingMode(false)
        let view = savedViews.find(v => v._id === selectedSavedViewId)
        await updateView(view)
    }

    const removeBlock = ({ viewId, blockId }) => {
        let savedViewsCopy = JSON.parse(JSON.stringify(savedViews))
        let view = savedViewsCopy.find(v => v._id === viewId)

        if (view) {
            view.blocks = view.blocks.filter(b => b._id !== blockId)
            for (const key in view.layouts) {
                view.layouts[key] = view.layouts[key].filter(l => l.i !== blockId)
            }

            setSavedViews(savedViewsCopy)
        }
    }

    const addBlock = (block) => {
        let savedViewsCopy = JSON.parse(JSON.stringify(savedViews))
        let view = savedViewsCopy.find(v => v._id === selectedSavedViewId)

        if (view) {
            let _id = String(Math.random())
            view.blocks.push({
                _id,
                type: block.type,
                title: block.name
            })
            for (const l in view.layouts) {
                let layoutForBlock = {
                    i: _id,
                    x: Math.random() * 48,
                    y: Infinity,
                    w: block.type.includes('chart') ? 16 : 8,
                    h: 4,
                    minW: 6,
                    maxW: 48,
                    minH: 3,
                    maxH: 14,
                }
                view.layouts[l].push(layoutForBlock)
            }

            setSavedViews(savedViewsCopy)
            setBlockEditorModalOpen(false)
        }
    }

    let blocks = [
        { type: 'total scans', name: "Total scans" },
        { type: 'first time scans', name: 'First-time scans' },
        { type: 'total referral revenue', name: 'Referral Revenue' },
        { type: 'total referral count', name: 'Referrals' },
        { type: 'returnRate', name: 'Return rate' },
        { type: 'scan chart', name: 'Scans over time' },
        { type: 'return rate chart', name: 'Return rate over time' },
        { type: 'regulars chart', name: 'Regulars over time' },
        { type: 'return rate loyalty comparison chart', name: 'Return Rate Loyalty Comparison' },
        { type: 'return rate winners chart', name: 'Return Rate Winners' },
        { type: 'revenue winners chart', name: 'Revenue Winners' },
    ]

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                height: '100vh',
                overflow: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                userSelect: 'none'
            }}
        >
            <Dialog onClose={e => setBlockEditorModalOpen(false)} open={blockEditorModalOpen} fullWidth>
                <DialogTitle>Add Block</DialogTitle>
                <DialogContent>
                    <List>
                        {blocks.map((b) => (
                            <ListItemButton onClick={e => addBlock(b)} variant='rounded' key={b.name} >
                                {b.name}
                            </ListItemButton>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>

            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 1, mb: 4 }}>
                <Stack spacing={1} direction="row" justifyContent="space-between" sx={{ p: 1, position: 'relative', top: 0, width: 1 }} >
                    <Box>

                    </Box>
                    <Box spacing={1} direction="row" alignItems="start" sx={{ whiteSpace: 'nowrap' }}>
                        {editingMode ?
                            <React.Fragment>
                                <Button startIcon={<Add />} variant="contained" onClick={e => setBlockEditorModalOpen(true)} sx={{ ml: 1 }} >Add block</Button>
                                <Button startIcon={<Close />} variant="contained" onClick={handleCancelEditing} sx={{ ml: 1 }} >Discard changes</Button>
                                <Button startIcon={<Done />} variant="contained" onClick={handleDoneEditing} sx={{ ml: 1 }} >Save</Button>
                            </React.Fragment>
                            : <Button startIcon={<Edit />} onClick={handleStartEditing} sx={{ ml: 1 }} >Edit view</Button>
                        }
                    </Box>
                </Stack>
                {savedViews &&
                    <ResponsiveReactGridLayout
                        {...props}
                        layouts={savedViews.find(v => v._id === selectedSavedViewId)?.layouts}
                        onBreakpointChange={onBreakpointChange}
                        onLayoutChange={onLayoutChange}
                        isResizable={editingMode}
                        isDraggable={editingMode}
                        // WidthProvider option
                        measureBeforeMount={true}
                        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                        // and set `measureBeforeMount={true}`.
                        // useCSSTransforms={true}
                        compactType={'vertical'}
                        preventCollision={false}
                        style={{ position: 'relative' }}
                    >
                        {generateDOM()}
                    </ResponsiveReactGridLayout>
                }
            </Container>
        </Box >
    );
}

BasicGridLayout.defaultProps = {
    className: 'layout',
    rowHeight: 50,
    onLayoutChange: function () { },
    // breakpoints: { lg: 1200, sm: 600 },
    breakpoints: { lg: 1200, md: 900, sm: 600, xs: 0 },
    // cols: { lg: 48, sm: 18 },
    cols: { lg: 48, md: 32, sm: 18, xs: 1 },
    // initialLayout: generateLayout(),
};