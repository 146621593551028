import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../util';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Title from '../text/title';

const CustomTooltip = () => <div>Return Rate</div>;

export default function ReturnRateChart({ token, setToken }) {
    const [data, setData] = useState(false);
    let navigate = useNavigate()
    const theme = useTheme();

    useEffect(() => {
        let status;
        let oneYearAgo = dayjs().subtract(12, 'm').startOf('m').valueOf();

        fetch('/api/stats/return-rate?'
            // + new URLSearchParams({
            //     bucketBy: 'month'
            // })
            ,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                // body: JSON.stringify(filter)
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                json.forEach(month => {
                    month.returnRate = (month.returnRate * 100).toFixed(1)

                    if (month.month === 1) {
                        month.chartName = month.year
                    } else {
                        // dayjs months are 0-indexed
                        month.chartName = dayjs().month(month.month - 1).format('MMM')
                    }
                })

                setData(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <React.Fragment>
            <Title>Return Rate Over Time</Title>
            <ResponsiveContainer height={'85%'}>
                <LineChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="chartName"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                        tickFormatter={tick => `${tick}%`}
                        domain={[0, dataMax => {
                            return 100
                        }]}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.secondary,
                                ...theme.typography.body1,
                            }}
                        >
                            Return Rate (%)
                        </Label>
                    </YAxis>
                    <Line
                        isAnimationActive={true}
                        type="monotone"
                        dataKey="returnRate"
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                    <Tooltip contentStyle={{backgroundColor: theme.palette.background.paper}} />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}