import { ListItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../util';

export default function PageTemplateSelectorMenu({ token, setToken, open, setOpen, pageTemplates, anchorEl, selectedPageTemplateId, setSelectedPageTemplateId, updateEndpointUrl, entityToUpdate }) {
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    const selectPageTemplate = (pageTemplateId) => {
        if (!token)
            return logOut({ navigate, setToken })

        let copy = JSON.parse(JSON.stringify(entityToUpdate))
        copy.pageTemplateId = pageTemplateId

        let status

        fetch(updateEndpointUrl + entityToUpdate._id, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(copy)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if(status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }
                setSelectedPageTemplateId(pageTemplateId)
                handleClose()
            })
            .catch(err => {
                console.log(err)
            })
    };

    return (
        <Menu
            id="page-template-selector-menu"
            anchorEl={anchorEl}
            open={open && Boolean(anchorEl)}
            onClose={handleClose}
        > {[
            (
                <ListItem key="select prompt" tabIndex={0} disabled >Select Landing Page</ListItem>
            ),
            (
                <MenuItem
                    key={'page template not selected'}
                    onClick={e => selectPageTemplate(null)}
                    selected={selectedPageTemplateId === null || selectedPageTemplateId === undefined}
                >
                    None (instant redirect)
                </MenuItem>
            ),
            (pageTemplates && pageTemplates
                .map(pageTemplate =>
                    <MenuItem
                        key={pageTemplate._id}
                        onClick={e => selectPageTemplate(pageTemplate._id)}
                        selected={selectedPageTemplateId === pageTemplate._id}
                    >
                        {pageTemplate.name}
                    </MenuItem>
                )
            )
        ]}
        </Menu>
    );
}
