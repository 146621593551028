import {
    useEffect,
    useCallback,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import * as React from "react";
import { useTheme } from "@emotion/react";
import debounce from "./../network/debounce";
import { updateQrCode } from "./../network/qr-code";
import { createSavedView, deleteSavedView } from "./../network/saved-view";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import { Box, Button, Stack } from "@mui/material";
import CreateSavedViewDialog from "../dialogs/create-saved-view-dialog";
import { Delete, Lock, Pinch, Save } from "@mui/icons-material";

const ORIGIN = Object.freeze({ x: 0, y: 0 });

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1 } = window;

function diffPoints(p1, p2) {
    return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1, p2) {
    return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1, scale) {
    return { x: p1.x / scale, y: p1.y / scale };
}

const ZOOM_SENSITIVITY = 1000; // bigger for lower zoom per scroll

// Drawing functions

var TO_RADIANS = Math.PI / 180

// used for setting the 
function hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return r + "," + g + "," + b;
}

function roundedRect(ctx, x, y, w, h, radius) {
    var r = x + w;
    var b = y + h;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(r - radius, y);
    ctx.quadraticCurveTo(r, y, r, y + radius);
    ctx.lineTo(r, y + h - radius);
    ctx.quadraticCurveTo(r, b, r - radius, b);
    ctx.lineTo(x + radius, b);
    ctx.quadraticCurveTo(x, b, x, b - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.fill();
    ctx.closePath()
}

function ellipse(ctx, x, y, w, h) {
    let xe = x + w,           // x-end
        ye = y + h,           // y-end
        xm = x + w / 2,       // x-middle
        ym = y + h / 2;       // y-middle

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, ym);
    ctx.quadraticCurveTo(x, y, xm, y);
    ctx.quadraticCurveTo(xe, y, xe, ym);
    ctx.quadraticCurveTo(xe, ye, xm, ye);
    ctx.quadraticCurveTo(x, ye, x, ym);
    ctx.fill();
    ctx.restore();
}

function circle(ctx, x, y, radius) {
    ctx.beginPath()
    ctx.arc(x + radius, y + radius, radius, 0, 2 * Math.PI, false)
    ctx.fill()
}

let getIconColor = visitCount =>
    visitCount >= 5 ?
        "success" :
        visitCount >= 2 ?
            "info" :
            "warning"

export default function CanvasV2(props) {
    let navigate = useNavigate()
    let theme = useTheme()
    // my state
    const [canvasClick, setCanvasClick] = useState({})
    const [selectedItem, setSelectedItem] = useState(null)
    const [canvasCursor, setCanvasCursor] = useState('default')
    // const [spaceBarPressed, setSpaceBarPressed] = useState(false);
    const [dragTables, setDragTables] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [selectedSavedView, setSelectedSavedView] = useState(false);
    const [createSavedViewDialogOpen, setCreateSavedViewDialogOpen] = useState(false);
    const mouseVirtualPosRef = useRef(ORIGIN);
    const updatedQrCode = useRef(null);
    // The original location of each table is used for calculating click offset while dragging
    var tableX = useRef(null);
    var tableY = useRef(null);

    // canvas state
    const canvasRef = useRef(null);
    const [context, setContext] = useState(null);
    const [scale, setScale] = useState(1);
    const [offset, setOffset] = useState(ORIGIN);
    const [mousePos, setMousePos] = useState(ORIGIN);
    const [viewportTopLeft, setViewportTopLeft] = useState(ORIGIN);
    const isResetRef = useRef(false);
    const lastMousePosRef = useRef(ORIGIN);
    const lastOffsetRef = useRef(ORIGIN);

    const debouncedUpdateQrCode = useCallback(debounce(updateQrCode, 400), []);

    function canvasMouseToVirtualCoords(mouse, viewportTopLeft, scale) {
        return addPoints(scalePoint(mouse, scale), viewportTopLeft);
    }

    // drawing functions

    const tableColor = theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700]

    const tableActiveColor = theme.palette.mode === 'light'
        ? theme.palette.grey[400]
        : theme.palette.grey[600]

    const backgroundHex = theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900]

    const backgroundColorForFade = hexToRgb(backgroundHex)

    const draw = (ctx) => {
        if (!props.mapData)
            return

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        // Draw the grid lines

        ctx.strokeStyle = theme.palette.mode === 'light'
            ? '#eee'
            : '#333';

        let boundary = 4000,
            gridSquareSize = 20

        for (let x = -1 * boundary; x < boundary; x += gridSquareSize) {
            ctx.beginPath();
            ctx.moveTo(x, -1 * boundary);
            ctx.lineTo(x, boundary);
            ctx.stroke();
        }

        for (let y = -1 * boundary; y < boundary; y += gridSquareSize) {
            ctx.beginPath();
            ctx.moveTo(-1 * boundary, y);
            ctx.lineTo(boundary, y);
            ctx.stroke();
        }

        props.mapData.forEach((table, index) => {
            if (!table.qr.rotation)
                drawTable(ctx, table)
            else
                drawRotatedTable(ctx, table)
        })
    }

    function drawScans(ctx, table) {
        let { x, y, width, height } = table.qr
        let centerX = x + width / 2,
            centerY = y + height / 2
        // ctx.font = "400 1rem Roboto";
        ctx.font = "400 1rem Roboto, sans-serif";
        ctx.textAlign = "center";
        ctx.fillStyle = theme.palette.text.primary
        let numScans = table.scans?.length
        ctx.fillText(table.qr?.name ?? "Untitled Table", centerX, centerY - (numScans > 0 ? 4 : - 4));
        let radius = 6
        let distanceBetween = 6
        let offsetX = ((numScans - 1) * (distanceBetween + (radius * 2))) / 2

        // draw a circle for each scan
        table.scans.forEach((scan, index) => {
            ctx.beginPath()
            ctx.fillStyle = theme.palette[getIconColor(scan.guest.visitCount)][theme.palette.mode];
            ctx.arc(centerX - offsetX + index * (distanceBetween + (2 * radius)), centerY + 12, radius, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.closePath()
        })
    }

    function drawTable(ctx, table) {
        ctx.save();
        let { x, y, width: w, height: h } = table.qr
        let style = table?.qr._id === selectedItem?.qr._id ? tableActiveColor : tableColor
        ctx.fillStyle = style;

        if (table.qr.shape === "Oval") {
            if (table.qr.width === table.qr.height) {
                circle(ctx, x, y, w / 2)
            } else {
                ellipse(ctx, x, y, w, h)
            }
        } else {
            roundedRect(ctx, x, y, w, h, 10)
        }
        if (!table.qr.rotation)
            drawScans(ctx, table)
        ctx.restore();
    }

    function drawRotatedTable(ctx, table) {
        // save the current co-ordinate system 
        // before we screw with it
        ctx.save();

        // move to the middle of where we want to draw our image
        let { x, y, width, height, rotation } = table.qr
        let offsetX = width / 2,
            offsetY = height / 2,
            centerX = x + offsetX,
            centerY = y + offsetY
        ctx.translate(centerX, centerY);

        // rotate around that point, converting our 
        // angle from degrees to radians 
        ctx.rotate(rotation * TO_RADIANS);
        let newTable = JSON.parse(JSON.stringify(table))
        // draw it up and to the left by half the width and height of the table
        newTable.qr.x = offsetX * -1 //(w/2)*-1
        newTable.qr.y = offsetY * -1 //(h/2)*-1

        drawTable(ctx, newTable);

        // and restore the co-ords to how they were when we began
        ctx.restore();
        drawScans(ctx, table)
    }

    // canvas functions

    // update last offset
    useEffect(() => {
        lastOffsetRef.current = offset;
    }, [offset]);

    // reset
    const reset = useCallback(
        (context) => {
            // adjust for device pixel density
            context.canvas.width = props.canvasWidth * ratio;
            context.canvas.height = props.canvasHeight * ratio;
            context.scale(ratio, ratio);
            setScale(1);

            // reset state and refs
            setContext(context);
            setOffset(ORIGIN);
            setMousePos(ORIGIN);
            setViewportTopLeft(ORIGIN);
            lastOffsetRef.current = ORIGIN;
            lastMousePosRef.current = ORIGIN;

            // this thing is so multiple resets in a row don't clear canvas
            isResetRef.current = true;
        },
        [props.canvasWidth, props.canvasHeight]
    );

    // functions for panning around canvas
    const mouseMove = useCallback((event) => {
        if (context) {
            const currentMousePos = { x: event.pageX, y: event.pageY }; // use document so can pan off element
            if (dragging && (!dragTables || !selectedItem)) {
                const lastMousePos = lastMousePosRef.current;
                const mouseDiff = diffPoints(currentMousePos, lastMousePos);
                setOffset((prevOffset) => addPoints(prevOffset, mouseDiff));
                setSelectedSavedView(null)
            }
            lastMousePosRef.current = currentMousePos;
        }
    },
        [context, /* spaceBarPressed, */ canvasClick, selectedItem, dragging]
    );

    const mouseUp = useCallback(
        async () => {
            setCanvasClick(null)
            setDragging(false)

            updatedQrCode.current = null
            tableX.current = null;
            tableY.current = null;
        }, [mouseMove, dragging]
    );

    // This handles dragging tables around
    function mouseMove2(e, ctx) {
        if (!props.mapData || !canvasClick || !selectedItem?.qr)
            return

        let updatedQrCode;
        if (dragging && dragTables & isWithinRectangle(canvasClick.offsetX, canvasClick.offsetY, selectedItem.qr.x, selectedItem.qr.y, selectedItem.qr.width, selectedItem.qr.height)) {
            props.setMapData(data => {
                let mapData = JSON.parse(JSON.stringify(data))
                let table = mapData.find(table => table?.qr._id === selectedItem?.qr._id)

                if (!table)
                    return data
                // console.log("canvasClick.offsetX")
                // console.log(canvasClick.offsetX)
                // console.log("tableX?.current")
                // console.log(tableX?.current)
                // console.log("canvasClick.x")
                // console.log(canvasClick.x)
                let clickOffsetX = (canvasClick.x - tableX?.current) || 0
                let clickOffsetY = (canvasClick.y - tableY?.current) || 0
                // console.log("clickOffsetX")
                // console.log(clickOffsetX)
                // console.log("mouseVirtualPosRef.current.x")
                // console.log(mouseVirtualPosRef.current.x)
                // console.log("clickOffsetX")
                // console.log(clickOffsetX)
                let newX = mouseVirtualPosRef.current.x - clickOffsetX,
                    newY = mouseVirtualPosRef.current.y - clickOffsetY

                table.qr.x = newX
                table.qr.y = newY
                updatedQrCode = table.qr;

                debouncedUpdateQrCode({ token: props.token, setToken: props.setToken, navigate, updatedQrCode })
                return mapData;
            })
        }
    }

    useEffect(() => {
        let canvas = canvasRef.current
        //     canvas.onmousedown = mouseDown2;
        //     canvas.onmouseup = mouseUp2;
        //     canvas.onmouseout = mouseUp2
        canvas.onmousemove = e => mouseMove2(e, context);

        document.addEventListener("mousemove", mouseMove);
        document.addEventListener("touchmove", mouseMove);
        document.addEventListener("mouseup", mouseUp);
        document.addEventListener("touchend", mouseUp);

        return () => {
            document.removeEventListener("mousemove", mouseMove);
            document.addEventListener("touchmove", mouseMove);
            document.removeEventListener("mouseup", mouseUp);
            document.addEventListener("touchend", mouseUp);
        }
    }, [ /* spaceBarPressed, */ dragging, canvasClick, selectedItem, mouseVirtualPosRef, tableX, tableY])

    const mouseDown = useCallback(
        (event) => {
            let eventCoords = {}

            if (event.touches) {
                eventCoords = {
                    x: event.touches[0].pageX,
                    y: event.touches[0].pageY
                }
            } else {
                eventCoords = {
                    x: event.pageX,
                    y: event.pageY
                }
            }

            // drag to pan when spacebar is pressed

            lastMousePosRef.current = eventCoords;
            setCanvasClick(mouseVirtualPosRef.current)
            setDragging(true)

            let item = getCanvasItemByLocation(mouseVirtualPosRef.current.x, mouseVirtualPosRef.current.y)

            if (item) {
                setSelectedItem(item)
                tableX.current = item.qr.x
                tableY.current = item.qr.y
            } else {
                setSelectedItem(null)
                tableX.current = null
                tableY.current = null
            }

        },
        [mouseMove, mouseUp, /* spaceBarPressed, */  canvasClick, dragging, props.mapData]
    );

    // setup canvas and set context
    useLayoutEffect(() => {
        if (canvasRef.current) {
            // get new drawing context
            const renderCtx = canvasRef.current.getContext("2d");

            if (renderCtx) {
                reset(renderCtx);
            }
        }
    }, [reset, props.canvasHeight, props.canvasWidth]);

    // pan when offset or scale changes
    useLayoutEffect(() => {
        if (context && lastOffsetRef.current) {
            const offsetDiff = scalePoint(
                diffPoints(offset, lastOffsetRef.current),
                scale
            );
            context.translate(offsetDiff.x, offsetDiff.y);
            setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
            isResetRef.current = false;
        }
    }, [context, offset, scale]);

    // draw
    useLayoutEffect(() => {
        if (context) {

            // clear canvas but maintain transform

            const storedTransform = context.getTransform();
            context.canvas.width = context.canvas.width;

            context.setTransform(storedTransform);

            draw(context)
        } else {
            console.log('canvas context not found - can\'t draw')
        }
    }, [
        props.mapData,
        props.canvasWidth,
        props.canvasHeight,
        context,
        scale,
        offset,
        viewportTopLeft,
        selectedItem
    ]);

    // add event listener on canvas for mouse position
    useEffect(() => {
        const canvasElem = canvasRef.current;
        if (canvasElem === null) {
            return;
        }

        function handleUpdateMouse(event) {
            if (canvasRef.current) {

                let eventCoords = {}

                if (event.touches) {
                    eventCoords = {
                        x: event.touches[0].pageX,
                        y: event.touches[0].pageY
                    }
                } else {
                    eventCoords = {
                        x: event.pageX,
                        y: event.pageY
                    }
                }

                const viewportMousePos = eventCoords

                const topLeftCanvasPos = {
                    x: canvasRef.current.offsetLeft,
                    y: canvasRef.current.offsetTop
                };

                setMousePos(diffPoints(viewportMousePos, topLeftCanvasPos));

                mouseVirtualPosRef.current = canvasMouseToVirtualCoords(mousePos, viewportTopLeft, scale)
                let item = getCanvasItemByLocation(mouseVirtualPosRef.current.x, mouseVirtualPosRef.current.y)

                if (dragTables) {
                    if (dragging && selectedItem)
                        return setCanvasCursor('grabbing')


                    if (item)
                        return setCanvasCursor('grab')

                }

                if (item)
                    return setCanvasCursor('pointer')

                setCanvasCursor('move');
            }
        }

        canvasElem.addEventListener("mousemove", handleUpdateMouse, { passive: true });
        canvasElem.addEventListener("touchmove", handleUpdateMouse, { passive: true });
        // canvasElem.addEventListener("wheel", handleUpdateMouse, { passive: true });
        return () => {
            canvasElem.removeEventListener("mousemove", handleUpdateMouse, { passive: true });
            canvasElem.removeEventListener("touchmove", handleUpdateMouse, { passive: true });
            // canvasElem.removeEventListener("wheel", handleUpdateMouse, { passive: true });
        };
    }, [mousePos, viewportTopLeft, scale, dragging, selectedItem]);

    // useEffect(() => {
    //     spaceBarPressed ? setCanvasCursor('grab') : setCanvasCursor('default')
    // }, [spaceBarPressed])

    // add event listener on canvas for zoom
    useEffect(() => {
        const canvasElem = canvasRef.current;
        if (canvasElem === null) {
            return;
        }

        // this is tricky. Update the viewport's "origin" such that
        // the mouse doesn't move during scale - the 'zoom point' of the mouse
        // before and after zoom is relatively the same position on the viewport
        // function handleWheel(event) {
        //     event.preventDefault();
        //     if (context) {
        //         const zoom = 1 - event.deltaY / ZOOM_SENSITIVITY;
        //         const viewportTopLeftDelta = {
        //             x: (mousePos.x / scale) * (1 - 1 / zoom),
        //             y: (mousePos.y / scale) * (1 - 1 / zoom)
        //         };
        //         const newViewportTopLeft = addPoints(
        //             viewportTopLeft,
        //             viewportTopLeftDelta
        //         );

        //         context.translate(viewportTopLeft.x, viewportTopLeft.y);
        //         context.scale(zoom, zoom);
        //         context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);

        //         setViewportTopLeft(newViewportTopLeft);
        //         setScale(scale * zoom);
        //         isResetRef.current = false;
        //     }
        // }

        // canvasElem.addEventListener("wheel", handleWheel);
        return () => { }
        // canvasElem.removeEventListener("wheel", handleWheel);
    }, [context, mousePos.x, mousePos.y, viewportTopLeft, scale]);

    // keep track of whether spacebar is pressed
    // useEffect(() => {
    //     document.addEventListener("keydown", e => {
    //         if (e.code === "Space") {
    //             e.preventDefault()
    //             setSpaceBarPressed(true)
    //         }
    //     })
    //     document.addEventListener("keyup", e => {
    //         if (e.code === "Space") {
    //             e.preventDefault()
    //             setSpaceBarPressed(false)
    //         }
    //     })

    //     return () => {
    //         document.removeEventListener("keydown", e => {
    //             if (e.code === "Space") {
    //                 e.preventDefault()
    //                 setSpaceBarPressed(true)
    //             }
    //         })
    //         document.removeEventListener("keyup", e => {
    //             if (e.code === "Space") {
    //                 e.preventDefault()
    //                 setSpaceBarPressed(false)
    //             }
    //         })
    //     }
    // }, [])

    // my logic for managing tables
    function getCanvasItemByLocation(x, y) {
        if (!props.mapData)
            return
        let item = props.mapData.find(item => {
            let qr = item.qr
            // short circuiting makes this slightly more efficient
            if (x < qr.x || x > (qr.x + qr.width))
                return false
            if (y < qr.y || y > (qr.y + qr.height))
                return false
            return true
        })
        return item
    }

    function isWithinRectangle(clickX, clickY, x, y, w, h) {
        if (clickX < x || clickX > (x + w))
            return false
        if (clickY < y || clickY > (y + h))
            return false
        return true
    }

    // Update selectedQr used by guest-map page to show visits in side list
    useEffect(() => {
        let qr = JSON.parse(JSON.stringify(selectedItem))
        props.setSelectedQr(qr);
    }, [selectedItem])

    async function deleteView({ id }) {

        let isDeleted = await deleteSavedView({ token: props.token, setToken: props.setToken, navigate, id })

        if (isDeleted) {
            props.setSavedViews(views => views.filter(v => v._id !== id))
            setSelectedSavedView(null)
        }
    }

    const restoreView = useCallback(
        ({ id, context, x, y, scale }) => {
            if (context && !isResetRef.current) {
                reset(context)
                // adjust for zoom change
                // const viewportTopLeftDelta = {
                //     x: (props.canvasWidth / 2) * ((scale - 1) / scale) * (scale > 1 ? 1 : -1),
                //     y: (props.canvasHeight / 2) * ((scale - 1) / scale) * (scale > 1 ? 1 : -1)
                // }

                // const newViewportTopLeft = addPoints(
                //     viewportTopLeft,
                //     viewportTopLeftDelta
                // )

                // context.translate(viewportTopLeft.x, viewportTopLeft.y)
                context.scale(scale, scale)
                // context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y)

                // setViewportTopLeft(newViewportTopLeft)

                // reset state and refs
                setScale(scale)
                setContext(context)
                setOffset({ x, y })

                // this thing is so multiple resets in a row don't clear canvas
                isResetRef.current = true

                let view = props.savedViews.find(v => v._id === id)
                setSelectedSavedView(view)
            }
        },
        [props.canvasWidth, props.canvasHeight, props.savedViews]
    )

    return (
        <div style={{ overflow: 'hidden', maxHeight: 'calc(100% - 64px)' }}>
            <Box sx={{ position: 'relative', width: 1, height: 0 }} >
                <Stack spacing={1} direction="row" justifyContent="space-between" sx={{ p: 1, position: 'absolute', top: 0, width: 1 }} >
                    <Box
                        direction="row"
                        alignItems="start"
                        sx={{
                            overflowX: 'auto', display: 'inline-block', whiteSpace: 'nowrap',
                            // background: `linear-gradient(to right, rgba(255,255,255,0) calc(100% - 10px),rgba(${backgroundColorForFade},1) 100%)` 
                        }}
                    >
                        {props.savedViews.map(({ _id, name, x, y, scale }) =>
                            <Button
                                variant="contained"
                                key={_id}
                                sx={{ mr: 1 }}
                                onClick={() => context && restoreView({ id: _id, context, x, y, scale })}
                                color={_id === selectedSavedView?._id ? "success" : "primary"}
                            >
                                {name}
                            </Button>
                        )}
                    </Box>
                    <Box spacing={1} direction="row" alignItems="start" sx={{ whiteSpace: 'nowrap' }}>
                        {dragTables ?
                            <React.Fragment>
                                {selectedSavedView?._id ?
                                    <Button startIcon={<Delete />} variant="contained" color="error" onClick={() => deleteView({ id: selectedSavedView._id })}>Delete This View</Button>
                                    :
                                    <Button startIcon={<Save />} variant="contained" onClick={() => setCreateSavedViewDialogOpen(true)}>Save This View</Button>
                                    // <Button variant="contained" onClick={() => saveView({ name: 'saved view ' + (props.savedViews.length + 1), x: offset.x, y: offset.y, scale: scale })}>Save View</Button>
                                }
                                <Button startIcon={<Lock />} variant="contained" onClick={() => setDragTables(false)} sx={{ ml: 1 }} >Lock map</Button>
                            </React.Fragment>
                            :
                            <Button startIcon={<Pinch />} variant="contained" onClick={() => setDragTables(true)} sx={{ ml: 1 }} >Edit map</Button>
                        }
                    </Box>
                </Stack>
            </Box>
            <CreateSavedViewDialog token={props.token} setToken={props.setToken} open={createSavedViewDialogOpen} setOpen={setCreateSavedViewDialogOpen} savedView={{ x: offset.x, y: offset.y, scale: scale }} setState={props.setSavedViews}></CreateSavedViewDialog>
            {/* <Stack spacing={1} direction="column" sx={{ m: 1, position: 'relative', top: '40px' }} alignItems="start">
                <pre>scale: {scale}</pre>
                <pre>offset: {JSON.stringify(offset)}</pre>
                <pre>mousePos: {JSON.stringify(mousePos)}</pre>
                <pre>viewportTopLeft: {JSON.stringify(viewportTopLeft)}</pre>
            </Stack> */}
            <canvas
                // id='canvas'
                onMouseDown={mouseDown}
                onTouchStart={mouseDown}
                ref={canvasRef}
                width={props.canvasWidth * ratio}
                height={props.canvasHeight * ratio}
                style={{
                    // border: "2px solid #000",
                    width: `${props.canvasWidth}px`,
                    height: `${props.canvasHeight}px`,
                    cursor: canvasCursor
                }}
            ></canvas>
        </div>
    );
}
