import React from "react";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import { ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FiberManualRecord, MoreHoriz } from "@mui/icons-material";
import { roundIfInt } from "../util";

dayjs.extend(relativeTime)

let getIconColor = visitCount =>
    visitCount >= 5 ?
        "success" :
        visitCount >= 2 ?
            "info" :
            "warning"

function makePrimaryText(scan) {
    let text = ''

    if (scan.guest?.firstName && scan.guest?.lastName) {
        text += `${scan.guest.firstName} ${scan.guest.lastName}`
    } else if(scan.guest.emails?.length > 0){
        text += scan.guest.emails[0]
    } else if(scan.guest?.visitCount > 1) {
        text += 'An anonymous guest'
    } else {
        text += 'An unrecognized guest'
    }

    text += ` scanned at ${scan.qrName}`

    return text
}

function makeSecondaryText(scan) {
    let text = ''

    // text += ' '

    let piecesOfInfo = [dayjs(scan.createdAt).fromNow()]

    if (scan.guest?.visitCount) {
        if (scan.guest?.visitCount > 1) {
            piecesOfInfo.push(`${scan.guest.visitCount - 1} previous ${(scan.guest.visitCount != 2) ? 'visits' : 'visit'}`)
        } else {
            piecesOfInfo.push('no previous visits')
        }
    }

    if (scan.guest.avgSpend) {
        piecesOfInfo.push(`, avg. spend ${roundIfInt(scan.guest?.avgSpend)}`)
    }

    return text + piecesOfInfo.join(', ')
}

export default function Scan({ token, setToken, scan, hoveredItemId, setHoveredItemId, setAnchorEl, setAnchorData }) {

    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data)
            setAnchorData(data);
    };

    return (
        <ListItem
            key={scan._id}
            onMouseOver={() => setHoveredItemId(scan._id)}
            onMouseOut={() => setHoveredItemId(null)}
            secondaryAction={
                <IconButton
                    onClick={e => handleContextMenuClick(e, scan)}
                    sx={{ display: (hoveredItemId === scan._id) ? '' : 'none' }}
                    edge="end"
                    aria-label="more options"
                    value={JSON.stringify(scan)}
                >
                    <MoreHoriz />
                </IconButton>
            }
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            disablePadding
        >
            <ListItemButton
                variant='rounded'
            // selected={selectedQrCodeId === item._id}
            // onClick={e => setSelectedQrCodeId(item._id)}
            >
                <ListItemIcon>
                    <FiberManualRecord color={getIconColor(scan.guest.visitCount)} />
                </ListItemIcon>
                <ListItemText
                    primary={makePrimaryText(scan)}
                    secondary={makeSecondaryText(scan)}
                />
            </ListItemButton>
        </ListItem>
    )
}