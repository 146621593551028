import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import Container from '@mui/material/Container';
// import QrMapCard from "../cards/qr-map-card";
import QrMapCardV2 from "../cards/qr-map-card-V2";
import LiveGuestMapContentV2 from "../cards/live-guest-mapV2";
// mocks
import { mockGuestMapResponse } from "./../mocks/mock-api-responses"

const drawerWidth = 240;

export default function GuestMap({ token, setToken }) {
    const [mapData, setMapData] = useState(null);
    const [selectedQr, setSelectedQr] = useState(null);
    let navigate = useNavigate()

    function fetchMapData() {
        if (!token)
            return logOut({ navigate, setToken })

        let status;

        fetch('/api/map', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }
                if (localStorage.getItem('show demo data') === 'true') {
                    let joinedResponse = JSON.parse(JSON.stringify(json))
                    let mockData = localStorage.getItem('mock map data')

                    joinedResponse.forEach((realQr, index) => {
                        let mockQr = mockGuestMapResponse[index]
                        if (mockQr) {
                            realQr.scans = realQr.scans.concat(mockQr.scans)
                        }
                        realQr.scans.sort((a, b) => a.visitCount > b.visitCount ? 1 : -1)
                    })
                    setMapData(joinedResponse);
                } else {
                    setMapData(json)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchMapData()
    }, [token])

    // const ONE_MINUTE = 60000;
    // const FIFTEEN_SECONDS = 15000;
    const THIRTY_SECONDS = 30000;

    useEffect(() => {
        const interval = setInterval(() => {
            fetchMapData();
        }, THIRTY_SECONDS);

        return () => clearInterval(interval);
    }, [])

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                height: '100vh',
                overflow: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900]
            }}
        >
            <Toolbar />
            <Box sx={{ position: 'relative', height: 0 }} >
                {selectedQr &&
                    <Box sx={{ width: 'auto', minWidth: 371, position: 'absolute', top: 0, right: 0, mt: 6, }}>
                        <Paper sx={{ m: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
                            <QrMapCardV2 token={token} setToken={setToken} mapData={mapData} setMapData={setMapData} selectedQr={selectedQr} />
                        </Paper>
                    </Box>
                }
            </Box>
            <LiveGuestMapContentV2 token={token} setToken={setToken} mapData={mapData} setMapData={setMapData} setSelectedQr={setSelectedQr} />
        </Box>
    )
}