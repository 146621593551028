import React, { useState, useEffect } from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import Title from './../text/title';
import dayjs from 'dayjs'

dayjs().format()


export default function Total({ token, setToken, title, subtitle, linkText, linkPath, statEndpoint, statField, statPrefix = "" }) {
    const [number, setNumber] = useState(false);
    let navigate = useNavigate()

    useEffect(() => {
        let status;
        let sevenDaysAgo = dayjs().startOf('d').subtract(6, 'd').toDate();

        fetch(statEndpoint + new URLSearchParams({
            after: sevenDaysAgo
        }),
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        logOut({ navigate, setToken })
                    }

                    throw new Error()
                }


                if(!statField) {
                    setNumber(json)
                } else {
                    setNumber(json[statField])
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Typography component="p" variant="h4">
                {number == null || number === false ? "Loading" : `${statPrefix}${number.toLocaleString()}`}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
                {subtitle}
            </Typography>
            {linkText ?
                <div>
                    <Link color="primary" to={linkPath} component={NavLink} >
                        {linkText}
                    </Link>
                </div> :
                null
            }
        </React.Fragment>
    );
}