import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../util';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Label } from 'recharts';
import Title from '../text/title';

export default function ScanCountByAttributeChart({ token, setToken, attributeId, attributes, hideTitle }) {
    const [data, setData] = useState(false);
    let navigate = useNavigate()
    const theme = useTheme();

    useEffect(() => {
        let status;

        fetch('/api/stats/scans/bucketed-count?'
            + new URLSearchParams({
                attributeId
            })
            ,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                // body: JSON.stringify(filter)
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                setData(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [attributeId])

    return (
        <React.Fragment>
            {hideTitle ?
                null :
                <Title>Scans By {attributes?.find(i => i._id === attributeId)?.name ?? 'Attribute'}</Title>
            }
            <ResponsiveContainer height={hideTitle ? '100%' : '85%'}>
                <BarChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="name"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                        domain={['No scans with this attribute yet']}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                        // tickFormatter={tick => `${tick}`}
                        domain={[0, dataMax => Math.max(dataMax, 4)]}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.secondary,
                                ...theme.typography.body1,
                            }}
                        >
                            Scans
                        </Label>
                    </YAxis>
                    <Bar
                        isAnimationActive={true}
                        // type="monotone"
                        dataKey="count"
                        // stroke={theme.palette.primary.main}
                        fill={theme.palette.primary.main}
                    />
                    {/* <Tooltip contentStyle={{backgroundColor: theme.palette.background.paper}} /> */}
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}