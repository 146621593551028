import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import SimpleTextDialog from "../dialogs/simple-text-dialog";

export default function AttributeContextMenu({ token, setToken, anchorEl, setAnchorEl, anchorData, setAnchorData, setSelectedAttributeId, setAttributeList }) {
    let [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
    const open = Boolean(anchorEl);
    let navigate = useNavigate()

    const handleEditName = () => {
        setAnchorEl(null)
        setEditNameDialogOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorData(null);
    };

    const deleteAttribute = () => {
        fetch('/api/attributes/' + anchorData._id, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    if (response.status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return alert("attribute not deleted");
                } else {
                    handleClose()
                    setAttributeList(attributes => attributes.filter(attribute => attribute._id !== anchorData._id))
                    setSelectedAttributeId(null)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <React.Fragment>
            <Menu
                id="attribute-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEditName}>Edit</MenuItem>
                <MenuItem onClick={deleteAttribute} sx={{ color: 'firebrick' }}>Delete</MenuItem>
            </Menu>
            <SimpleTextDialog
                token={token}
                setToken={setToken}
                open={editNameDialogOpen}
                setOpen={setEditNameDialogOpen}
                handleCloseExternal={handleClose}
                apiEndpoint={'/api/attributes/' + anchorData?._id}
                value={anchorData}
                setValue={setAttributeList}
                dialogTitle={"Edit attribute name"}
                dbFieldname={"name"}
                inputLabel={"Name"}
                entityId={anchorData?._id}
                accountId={anchorData?.accountId}
            />
        </React.Fragment>
    );
}
