import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import SchedulesList from "./../cards/schedules-list";
import ScheduleDetails from "../cards/schedule-details";

const drawerWidth = 240;

export default function SchedulesV2({ token, setToken }) {
    const [schedules, setSchedules] = useState(null);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);
    const [forbidden, setForbidden] = useState(null);
    const [attributes, setAttributes] = useState(null);
    const [attributeValues, setAttributeValues] = useState(null);
    let navigate = useNavigate()

    useEffect(() => {
        async function fetchAttributesAndValues() {
            if (!token)
                return logOut({ navigate, setToken })

            let attributePromise = fetch('/api/attributes', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
            let valuesPromise = fetch('/api/attribute-values', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            try {
                let [attributesResponse, valuesResponse] = await Promise.all([attributePromise, valuesPromise]);
                let [attributesJson, valuesJson] = await Promise.all([attributesResponse.json(), valuesResponse.json()]);
                if (attributesResponse.status !== 200 || valuesResponse.status !== 200) {
                    if (attributesResponse.status === 401 || valuesResponse.status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return
                }
                setAttributes(attributesJson);
                setAttributeValues(valuesJson);
            } catch (err) {
                console.log(err)
            }
        }
        if (attributes === null || attributeValues === null)
            fetchAttributesAndValues();
    }, [])

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                height: '100vh',
                overflow: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900]
            }}
        >
            <Container
                maxWidth="lg"
                sx={{ display: 'flex' }}
                className="content-root"
            >
                <Toolbar />
                <Grid container spacing={3} className="flex-section"
                    sx={{
                        flexWrap: 'nowrap',
                    }}
                >
                    <Grid item xs={12} md={6} lg={4} className={"flex-col-scroll"}  >
                        <Paper sx={{
                            my: 4,
                            p: 2,
                            minHeight: 240,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <SchedulesList token={token} setToken={setToken} schedules={schedules} setSchedules={setSchedules} forbidden={forbidden} setForbidden={setForbidden} selectedScheduleId={selectedScheduleId} setSelectedScheduleId={setSelectedScheduleId} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={8} className={"flex-col-scroll"} >
                        <Paper
                            sx={{
                                my: 4,
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <ScheduleDetails token={token} setToken={setToken} schedules={schedules} setSchedules={setSchedules} forbidden={forbidden} setForbidden={setForbidden} attributes={attributes} attributeValues={attributeValues} setAttributeValues={setAttributeValues} scheduleId={selectedScheduleId} />
                        </Paper>
                    </Grid>
                </Grid>
                {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
        </Box>
    )
}