import React, { useState, useEffect } from "react";
import Title from "./../text/title";
import TableBarIcon from '@mui/icons-material/TableBar';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { Box, Button, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import { Add, LocalOffer, MoreHoriz } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import CreateAttributeDialog from "../dialogs/createAttributeDialog";
import AttributeContextMenu from "../menus/attribute-context-menu";

export default function Attributes({ token, setToken, attributeList, setAttributeList, selectedAttributeId, setSelectedAttributeId }) {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [nameDialogOpen, setNameDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);
    let navigate = useNavigate()

    useEffect(() => {
        if (!token)
            return logOut({ navigate, setToken })

        let status;

        fetch('/api/attributes', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if(status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }

                setAttributeList(json);
                setSelectedAttributeId(json[0]._id)
            })
            .catch(err => {
                console.log(err)
            })
    }, [token])

    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data)
            setAnchorData(data);
    };

    return (
        <React.Fragment>
            <Title>Attributes</Title>
            <List>
                {attributeList?.map(item =>
                    <ListItem
                        key={item._id}
                        onMouseOver={() => setHoveredItemId(item._id)}
                        onMouseOut={() => setHoveredItemId(null)}
                        secondaryAction={
                            <IconButton
                                onClick={e => handleContextMenuClick(e, item)}
                                sx={{ display: (hoveredItemId === item._id || anchorData?._id === item._id) ? '' : 'none' }}
                                edge="end"
                                aria-label="more options"
                                value={JSON.stringify(item)}
                            >
                                <MoreHoriz />
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            variant='rounded'
                            selected={selectedAttributeId === item._id}
                            onClick={e => setSelectedAttributeId(item._id)}
                        >
                            <ListItemIcon>
                                    <LocalOffer />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.name}
                            // secondary={!item.attributes ? "No attributes" : Object.keys(item.attributes).length + " attributes"}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <AttributeContextMenu token={token} setToken={setToken} anchorEl={anchorEl} setAnchorEl={setAnchorEl} anchorData={anchorData} setAnchorData={setAnchorData} setSelectedAttributeId={setSelectedAttributeId} setAttributeList={setAttributeList} />
            {!attributeList && "Loading"}
            <Box sx={{ mt: 2 }} >
                <Button startIcon={<Add />} onClick={() => setCreateDialogOpen(true)}>
                    Create Attribute
                </Button>
            </Box>
            <CreateAttributeDialog token={token} setToken={setToken} open={createDialogOpen} setOpen={setCreateDialogOpen} setAttributeList={setAttributeList} />
        </React.Fragment>
    )
}