import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import { Checkbox, FormControlLabel } from '@mui/material';

export default function CreateAttributeValueDialog({ token, setToken, open, setOpen, selectedAttributeId, setAttributeValueList }) {
    const [nameInput, setNameInput] = useState('')
    const [createAnother, setCreateAnother] = useState(false)
    let navigate = useNavigate()

    function handleClose(event) {
        setOpen(false)
    }

    const handleInputChange = (event) => {
        setNameInput(event.target.value);
        event.preventDefault();
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSubmit(event)
            event.preventDefault();
        }
    }

    function handleSubmit(event) {

        let status;
        fetch('/api/attribute-values', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
                name: nameInput,
                type: "string",
                attributeId: selectedAttributeId,
                accountId: selectedAttributeId
            })
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }
                setAttributeValueList(attributeValues => {
                    return [...attributeValues, json]
                });
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setNameInput('')
                event.preventDefault();
                if (!createAnother) {
                    handleClose()
                }
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth >
            <DialogTitle>Create Attribute Value</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This value will be available on this attribute.
                </DialogContentText>
                <TextField
                    autoFocus
                    value={nameInput}
                    margin="dense"
                    id="name"
                    label="Name"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder='Patio'
                    autoComplete='off'
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <FormControlLabel
                    label="Create another"
                    // sx={{ mb: 2 }}
                    control={
                        <Checkbox
                            checked={createAnother}
                            onChange={() => setCreateAnother(val => !val)}
                            inputProps={{ 'aria-label': 'Create another' }}
                        />
                    }
                />
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}
