import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import "@fontsource/roboto"
import './custom-grid.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

function ThemedApp() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () => {
      return createTheme({
        // typography: {
        //   fontFamily: 'Patua One, Roboto, sans-serif',
        // },
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: prefersDarkMode ? '#4c956c' : '#388158',
          },
          info: {
            main: prefersDarkMode ? '#0288D1' : '#349FDA',
          },
          // success: {
          //   main: prefersDarkMode ? '#0288D1' : '#349FDA',
          // }
        },
        components: {
          MuiListItemButton: {
            variants: [
              {
                props: { variant: 'rounded' },
                style: {
                  borderRadius: '6px',
                },
              }
            ]
          },
          MuiLink: {
            defaultProps: {
              underline: 'hover',
            },
          },
          MuiTooltip: {
            defaultProps: {
              // placement: "top",
              disableInteractive: true
            }
          }
        }
      })
    },
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemedApp />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
