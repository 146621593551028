import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, Checkbox, FormControlLabel, Collapse, Typography } from '@mui/material';

import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import ScanFilters from '../cards/scan-filters';

dayjs().format()
dayjs.extend(utc)

export default function CreateScheduleDialog({ token, setToken, open, setOpen, onClose, selectedSchedule, setSchedules, setForbidden, setAnchorEl }) {
    const [nameInput, setNameInput] = useState('')
    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().add(1, 'day').endOf('day'));
    const [weekDays, setWeekDays] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [filter, setFilter] = useState(null);
    let navigate = useNavigate()

    useEffect(() => {
        if (selectedSchedule) {
            let timeZone = selectedSchedule.timeZone

            setNameInput(selectedSchedule.name)

            // Convert to schedule timeZone to get the right time of day, then keep that time of day when converting into client time zone. This is needed bc mui date picker always uses client time zone.

            setStartDate(
                dayjs(selectedSchedule.startDate)
                    .tz(timeZone)
                    .tz(dayjs.tz.guess(), true)
            )

            setEndDate(
                dayjs(selectedSchedule.endDate)
                    .tz(timeZone)
                    .tz(dayjs.tz.guess(), true)
            )

            setWeekDays(selectedSchedule.weekDays ?? null)

            // This is just to convert from minutes since midnight into a dayjs instance for mui time picker. Doesn't need tz conversion.

            setStartTime(selectedSchedule.startTime ? dayjs().hour(Math.floor(selectedSchedule.startTime / 60)).minute(selectedSchedule.startTime % 60) : null)
            setEndTime(selectedSchedule.endTime ? dayjs().hour(Math.floor(selectedSchedule.endTime / 60)).minute(selectedSchedule.endTime % 60) : null)

            if (selectedSchedule.criteria) {

                // Adapt criteria from db format to the format that the Filter component uses

                let adaptedFilter = {
                    attributes: Object.entries(selectedSchedule.criteria).map(([key, value]) => {
                        return {
                            _id: key,
                            values: [value]
                        }
                    })
                }

                setFilter(adaptedFilter)
            }
        }
    }, [selectedSchedule])

    function handleClose() {
        onClose()
        setOpen(false)
    }

    const handleInputChange = (event) => {
        setNameInput(event.target.value);
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter')
            handleSubmit()
    }

    const handleWeekDays = (event, weekDays) => {
        console.log(weekDays)
        if (weekDays.length == 0 || weekDays.length == 7) {
            return setWeekDays(null)
        }
        setWeekDays(weekDays);
    };

    const handleWeekDaysCheckbox = (event) => {
        if (event.target.checked) {
            setWeekDays(null)
        } else {
            setWeekDays([2])
        }
    };

    const handleAllDayCheckbox = (event) => {
        if (event.target.checked) {
            setStartTime(null)
            setEndTime(null)
        } else {
            setStartTime(dayjs())
            setEndTime(dayjs().add(3, 'hours'))
        }
    };

    const handleFilterCheckbox = (event) => {
        if (event.target.checked) {
            setFilter(null)
        } else {
            setFilter({})
        }
    };

    function handleSubmit() {
        let status,
            startMinFromMidnight = null,
            endMinFromMidnight = null
        if (startTime && endTime) {
            let localStartTime = startTime?.startOf('min')
            startMinFromMidnight = localStartTime?.hour() * 60 + localStartTime.minute()
            let localEndTime = endTime.startOf('min')
            endMinFromMidnight = localEndTime.hour() * 60 + localEndTime.minute()
        }

        let body = selectedSchedule ? { ...selectedSchedule } : {}

        let startDateInScheduleTimeZone, endDateInScheduleTimeZone

        if(selectedSchedule) {

            // Convert into schedule time zone before PUT request

            startDateInScheduleTimeZone = startDate.tz(selectedSchedule.timeZone, true).toDate()
            endDateInScheduleTimeZone = endDate.tz(selectedSchedule.timeZone, true).toDate()
        } else {

            // Use UTC when creating a schedule, server will adjust it into account time zone

            let defaultScheduleCreationTimeZone = 'Etc/UTC'
            startDateInScheduleTimeZone = startDate.tz(defaultScheduleCreationTimeZone, true).toDate()
            endDateInScheduleTimeZone = endDate.tz(defaultScheduleCreationTimeZone, true).toDate()
        }

        body = {
            ...body,
            name: nameInput,
            startDate: startDateInScheduleTimeZone,
            endDate: endDateInScheduleTimeZone,
            weekDays: weekDays,
            startTime: startMinFromMidnight,
            endTime: endMinFromMidnight,
            criteria: null
        }

        // Adapt criteria from Filter component format to the db format before saving

        if (filter?.attributes?.length > 0) {
            let criteria = {}

            filter.attributes.forEach(a => {
                criteria[a._id] = a.values[0]
            })

            body.criteria = criteria
        }


        let endpoint = selectedSchedule ? `/api/schedules/${selectedSchedule._id}` : '/api/schedules/'
        fetch(endpoint, {
            method: selectedSchedule ? 'PUT' : 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    alert(json.message)
                    throw new Error(json.message)
                }
                setSchedules(schedules => {
                    let newValue = JSON.parse(JSON.stringify(schedules))
                    if (newValue.length > 0) {
                        let index = newValue.findIndex(i => i._id === json.schedule._id)
                        if (index !== -1) {
                            newValue[index] = json.schedule
                            return newValue
                        }
                        return [...newValue, json.schedule]
                    } else {
                        return [json.schedule];
                    }
                })
                setForbidden(json.forbidden)
                setAnchorEl(null)
                setOpen(false)
            })
            .catch(err => {
                console.error(err)
                setOpen(false)
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth >
            <DialogTitle>{selectedSchedule ? "Update" : "Create"} Schedule</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    Create a schedule
                </DialogContentText> */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3
                    }} >
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        required
                        value={nameInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        placeholder='Mealtime'
                        autoComplete='off'
                        fullWidth
                    />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }} >
                        <DatePicker
                            label="Start Date"
                            views={['year', 'month', 'day']}
                            value={startDate}
                            minDate={dayjs('2022-01-01')}
                            onChange={(newValue) => {
                                setStartDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="End Date"
                            views={['year', 'month', 'day']}
                            minDate={startDate}
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                    <Box
                        sx={{
                            display: 'block',
                            // flexDirection: 'column',
                            // mb: 3
                        }} >
                        <FormControlLabel
                            label="Schedule applies every day of the week"
                            sx={{ mb: 1 }}
                            control={
                                <Checkbox
                                    checked={Boolean(weekDays === null)}
                                    onChange={handleWeekDaysCheckbox}
                                    inputProps={{ 'aria-label': 'All week' }}
                                />
                            }
                        />
                        <Collapse in={weekDays !== null} mountOnEnter unmountOnExit>
                            <Box sx={{ mb: 3 }} >
                                <ToggleButtonGroup
                                    value={weekDays}
                                    onChange={handleWeekDays}
                                    aria-label="weekdays"
                                    size={"small"}
                                >
                                    <ToggleButton value={0} aria-label="sunday">
                                        Sunday
                                    </ToggleButton>
                                    <ToggleButton value={1} aria-label="sunday">
                                        Monday
                                    </ToggleButton>
                                    <ToggleButton value={2} aria-label="sunday">
                                        Tuesday
                                    </ToggleButton>
                                    <ToggleButton value={3} aria-label="sunday">
                                        Wednesday
                                    </ToggleButton>
                                    <ToggleButton value={4} aria-label="sunday">
                                        Thursday
                                    </ToggleButton>
                                    <ToggleButton value={5} aria-label="sunday">
                                        Friday
                                    </ToggleButton>
                                    <ToggleButton value={6} aria-label="sunday">
                                        Saturday
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Collapse>
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Schedule applies all day"
                            sx={{ mb: 1 }}
                            control={
                                <Checkbox
                                    checked={Boolean(!startTime && !endTime)}
                                    onChange={handleAllDayCheckbox}
                                    inputProps={{ 'aria-label': 'All day' }}
                                />
                            }
                        />
                        <Collapse in={Boolean(startTime && endTime)} mountOnEnter unmountOnExit>
                            <Stack direction="row" spacing={2} sx={{ mb: 2, mt: 2 }} >
                                <TimePicker
                                    ampm={true}
                                    label="Start Time"
                                    value={startTime}
                                    onChange={(newValue) => {
                                        setStartTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    ampm={true}
                                    label="End Time"
                                    value={endTime}
                                    minTime={startTime}
                                    onChange={(newValue) => {
                                        setEndTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </Collapse>
                    </Box>
                </LocalizationProvider>
                <Box>
                    <FormControlLabel
                        label="Schedule applies to all QR Codes"
                        // sx={{ mb: 2 }}
                        control={
                            <Checkbox
                                checked={!Boolean(filter)}
                                onChange={handleFilterCheckbox}
                                inputProps={{ 'aria-label': 'Apply schedule to all QR Codes' }}
                            />
                        }
                    />
                    <Collapse in={Boolean(filter)} mountOnEnter unmountOnExit>
                        <Box sx={{ mb: 1 }} >
                            <Typography variant='caption' >
                                Applies only to QR Codes that match all the attribute values set in this filter.
                            </Typography>
                        </Box>
                        <ScanFilters
                            token={token}
                            filter={filter}
                            setFilter={setFilter}
                            singleSelect={true}
                        />
                    </Collapse>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>{selectedSchedule ? "Save" : "Create"}</Button>
            </DialogActions>
        </Dialog >
    );
}
