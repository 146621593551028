import { useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink, Navigate } from "react-router-dom";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://fidomap.com/">
                Fido Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignUp({ token, setToken }) {
    const [timeZone, setTimeZone] = useState("America/Los_Angeles");
    const [captchaData, setCaptchaData] = useState(null);
    let theme = useTheme()

    const handleTimeZone = (event, newTz) => {
        if (newTz !== null) {
            setTimeZone(newTz);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let status;

        fetch('/api/sign-up', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                accountName: data.get('accountName'),
                firstName: data.get('firstName'),
                lastName: data.get('lastName'),
                timeZone: timeZone,
                email: data.get('email'),
                password: data.get('password'),
                captchaToken: captchaData['token']
            })
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    return alert(json.message);
                }

                setToken(json.token)
            })
    };

    if (token) {
        return <Navigate to="/" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                name="accountName"
                                required
                                fullWidth
                                id="accountName"
                                label="Business Name"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password (at least 10 characters)"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography gutterBottom>
                                Time Zone:
                            </Typography>
                            <ToggleButtonGroup
                                value={timeZone}
                                exclusive
                                onChange={handleTimeZone}
                                aria-label="time zone"
                            // size={"small"}
                            >
                                <ToggleButton value="America/Los_Angeles" aria-label="pacific time">
                                    Pacific
                                </ToggleButton>
                                <ToggleButton value="America/Denver" aria-label="mountain time">
                                    Mountain
                                </ToggleButton>
                                <ToggleButton value="America/Chicago" aria-label="central time">
                                    Central
                                </ToggleButton>
                                <ToggleButton value="America/New_York" aria-label="eastern time">
                                    Eastern
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12} s={6}>
                            <HCaptcha
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                onVerify={(token, ekey) => {
                                    console.log(token)
                                    setCaptchaData({token, ekey})}
                                }
                                theme={theme.palette.mode ?? 'light'}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!captchaData?.token}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to="/sign-in" variant="body2" component={NavLink}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}