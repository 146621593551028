import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { RotateRight, TableBar, TableRestaurant, Tune, Done, Circle, Square, Rectangle, Crop } from "@mui/icons-material";
import { List, Slider, Stack, ToggleButton, ToggleButtonGroup, Box, IconButton, ListItem, TextField, InputAdornment } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from "react-router-dom";
import { updateQrCode } from "./../network/qr-code";
import debounce from "./../network/debounce";
import Scan from "../items/scan";

const debouncedUpdateQrCode = debounce(updateQrCode, 300);

const getTableDimensions = ({ shape, sizeSelector, unitSize, width, height }) => {
    // console.log({ shape, sizeSelector, unitSize, width, height })

    if (sizeSelector === 'custom') {
        if (shape === 'Rectangle')
            return { width, height }
        return { width, height: width }
    }

    if (shape === 'Square' || shape === 'Circle') {
        if (sizeSelector === 'Small') return { width: 5 * unitSize, height: 5 * unitSize }
        if (sizeSelector === 'Medium') return { width: 7 * unitSize, height: 7 * unitSize }
        if (sizeSelector === 'Large') return { width: 9 * unitSize, height: 9 * unitSize }
        if (sizeSelector === 'X-Large') return { width: 11 * unitSize, height: 11 * unitSize }
    } else {
        // shape is Rectangle
        if (sizeSelector === 'Small') return { width: 7 * unitSize, height: 5 * unitSize }
        if (sizeSelector === 'Medium') return { width: 10 * unitSize, height: 7 * unitSize }
        if (sizeSelector === 'Large') return { width: 14 * unitSize, height: 9 * unitSize }
        if (sizeSelector === 'X-Large') return { width: 17 * unitSize, height: 11 * unitSize }
    }
}

let getShapeFromDbShape = (qr) =>
    qr.shape === "Oval" ?
        'Circle' :
        qr.width == qr.height ?
            'Square' :
            'Rectangle'

let getSizeSelectorFromDbSize = (qr) =>
    qr.height == 5 * unitSize ?
        'Small' :
        qr.height == 7 * unitSize ?
            'Medium' :
            qr.height == 9 * unitSize ?
                'Large' :
                qr.height == 11 * unitSize ?
                    'X-Large' :
                    'custom'

let unitSize = 10

const getSelectorFromRotation = (rotation) => {
    if (rotation == 0 || rotation == 45 || rotation == -45 || rotation == 90)
        return rotation
    return 'custom'
}

const getRotationFromSelector = (rotationSelector, rotationSlider) => {
    if (rotationSelector === 'custom' && rotationSlider)
        return rotationSlider
    return rotationSelector ?? 0
}

export default function QrMapCardV2({ token, setToken, mapData, setMapData, selectedQr }) {
    const [showTableControls, setShowTableControls] = useState(false);
    const [sizeSelector, setSizeSelector] = useState(getSizeSelectorFromDbSize(selectedQr.qr));
    const [width, setWidth] = useState(selectedQr.qr.width ?? 100);
    const [height, setHeight] = useState(selectedQr.qr.height ?? 100);
    const [rotationSlider, setRotationSlider] = useState(selectedQr.qr.rotation ?? '');
    const [rotationSelector, setRotationSelector] = useState(getSelectorFromRotation(selectedQr.qr.rotation ?? 0));
    const [shape, setShape] = useState(getShapeFromDbShape(selectedQr.qr));
    let navigate = useNavigate()

    useEffect(() => {
        setWidth(selectedQr.qr.width)
        setHeight(selectedQr.qr.height)
        setRotationSelector(getSelectorFromRotation(selectedQr.qr.rotation ?? 0))
        setRotationSlider(selectedQr.qr.rotation ?? 0)
        setSizeSelector(getSizeSelectorFromDbSize(selectedQr.qr))
        setShape(getShapeFromDbShape(selectedQr.qr))
    }, [selectedQr])

    useEffect(() => {
        let updatedQrCode;
        setMapData(data => {
            let mapData = JSON.parse(JSON.stringify(data)) // forces re-render
            let table = mapData.find(table => table?.qr._id === selectedQr?.qr._id)
            if (!table)
                return data
            table.qr.width = width
            table.qr.height = height
            table.qr.shape = shape === 'Rectangle' || shape === 'Square' ? 'Rectangle' : 'Oval'
            table.qr.rotation = getRotationFromSelector(rotationSelector, rotationSlider)
            updatedQrCode = table.qr
            debouncedUpdateQrCode({ token, setToken, navigate, updatedQrCode })
            // updateQrCode({token, updatedQrCode})
            return mapData;
        })
    }, [width, height, shape, rotationSelector, rotationSlider])

    const toggleShowTableControls = () => {
        setShowTableControls(val => !val)
    };

    const handleShapeSelectorChange = (event, newShape, width, height) => {
        if (newShape == null) {
            return
        }
        setShape(newShape)
        let dimensions = getTableDimensions({ shape: newShape, sizeSelector, unitSize, width, height })
        setWidth(dimensions.width);
        setHeight(dimensions.height);
    };

    const handleSizeChange = (event, newValue, width, height) => {
        if (newValue == null)
            return

        setSizeSelector(newValue)
        let dimensions = getTableDimensions({ shape, sizeSelector: newValue, unitSize, width, height })
        setWidth(dimensions.width);
        setHeight(dimensions.height);
    };

    const handleRotationSelectorChange = (event, newValue) => {
        if (newValue == null)
            return
        setRotationSelector(newValue);
    };

    const handleRotationSliderChange = (event, newValue) => {
        setRotationSlider(newValue)
    }

    const handleWidthAndHeightSliderChange = (event, newValue) => {
        setWidth(newValue);
        setHeight(newValue);
    };

    const handleWidthSliderChange = (event, newValue) => {
        setWidth(newValue);
    };

    const handleHeightSliderChange = (event, newValue) => {
        setHeight(newValue);
    };

    let getIconColor = visitCount =>
        visitCount >= 5 ?
            "success" :
            visitCount >= 2 ?
                "info" :
                "warning"

    return (
        <List>
            <ListItem
                secondaryAction={
                    <IconButton edge="end" aria-label="edit table"
                        onClick={toggleShowTableControls}
                    >
                        {showTableControls ?
                            <Done /> :
                            <Tune />
                        }
                    </IconButton>
                }
                disablePadding
                sx={{ mb: 1 }}
            >
                <Typography component="h2" variant="h6" color="primary">{selectedQr.qr.name}</Typography>
            </ListItem>
            <React.Fragment key={selectedQr.qr._id} >
                {showTableControls ?
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 2
                        }} >
                        <Typography gutterBottom>
                            Shape
                        </Typography>
                        <ToggleButtonGroup
                            value={shape}
                            exclusive
                            onChange={(e, newShape) => handleShapeSelectorChange(e, newShape, width, height)}
                            aria-label="table shape"
                            sx={{ mb: 1, overflowX: 'auto' }}
                            size={"small"}
                        >
                            <ToggleButton value="Circle" aria-label="oval">
                                Circle
                            </ToggleButton>
                            <ToggleButton value="Square" aria-label="rectangle">
                                Square
                            </ToggleButton>
                            <ToggleButton value="Rectangle" aria-label="rectangle">
                                Rectangle
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Typography gutterBottom>
                            Size
                        </Typography>
                        <ToggleButtonGroup
                            value={sizeSelector}
                            exclusive
                            onChange={(e, newSize) => handleSizeChange(e, newSize, width, height)}
                            aria-label="table shape"
                            sx={{ mb: 1, overflowX: 'auto' }}
                            size={"small"}
                        >
                            <ToggleButton value="Small" aria-label="small">
                                Small
                            </ToggleButton>
                            <ToggleButton value="Medium" aria-label="medium">
                                Medium
                            </ToggleButton>
                            <ToggleButton value="Large" aria-label="large">
                                Large
                            </ToggleButton>
                            <ToggleButton value="X-Large" aria-label="large">
                                X-Large
                            </ToggleButton>
                            <ToggleButton value='custom' aria-label="custom">
                                Custom
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {sizeSelector === 'custom' ?
                            shape === 'Rectangle' ?
                                <React.Fragment>
                                    <Typography gutterBottom>
                                        Width: {width}
                                    </Typography>
                                    <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <Crop />
                                        <Slider min={50} max={350} aria-label="Width slider" value={width} onChange={handleWidthSliderChange} onChangeCommitted={handleWidthSliderChange} />
                                    </Stack>
                                    <Typography gutterBottom>
                                        Height: {height}
                                    </Typography>
                                    <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <Crop />
                                        <Slider min={50} max={350} aria-label="Height slider" value={height} onChange={handleHeightSliderChange} onChangeCommitted={handleHeightSliderChange} />
                                    </Stack>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Typography gutterBottom>
                                        Width: {width}
                                    </Typography>
                                    <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <Crop />
                                        <Slider min={50} max={350} aria-label="Width slider" value={width} onChange={handleWidthAndHeightSliderChange} onChangeCommitted={handleWidthAndHeightSliderChange} />
                                    </Stack>
                                </React.Fragment>
                            : null
                        }
                        {shape !== 'Circle' ?
                            <React.Fragment>
                                <Typography gutterBottom>
                                    Orientation
                                </Typography>
                                <ToggleButtonGroup
                                    value={rotationSelector}
                                    exclusive
                                    onChange={handleRotationSelectorChange}
                                    aria-label="table shape"
                                    sx={{ mb: 1, overflowX: 'auto' }}
                                    size={"small"}
                                >
                                    <ToggleButton value={0} aria-label="Horizontal">
                                        Horiz
                                    </ToggleButton>
                                    {shape !== 'Square' ?
                                        <ToggleButton value={90} aria-label="Vertical">
                                            Vertical
                                        </ToggleButton>
                                        : null
                                    }
                                    <ToggleButton value={45} aria-label="Diagonal">
                                        45°
                                    </ToggleButton>
                                    {shape !== 'Square' ?
                                        <ToggleButton value={-45} aria-label="Diagonal">
                                            -45°
                                        </ToggleButton>
                                        : null
                                    }
                                    <ToggleButton value='custom' aria-label="custom">
                                        Custom
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {(rotationSelector === 'custom') ?
                                    <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <RotateRight />
                                        <Slider min={0} max={179} aria-label="Rotation slider" value={rotationSlider} onChange={handleRotationSliderChange} onChangeCommitted={handleRotationSliderChange} />
                                    </Stack>
                                    : null
                                }
                            </React.Fragment>
                            : null
                        }
                    </Box>
                    :
                    selectedQr.scans.length > 0 ?
                        selectedQr.scans.map((scan, index) =>
                            <Scan
                                scan={scan}
                                // hoveredItemId={hoveredItemId}
                                // setHoveredItemId={setHoveredItemId}
                                // setAnchorEl={setAnchorEl}
                                // setAnchorData={setAnchorData}
                            />
                        )
                        :
                        <Typography key="no recents warning" variant="caption">No recent scans</Typography>
                }
            </React.Fragment>
        </List>
    )
}