import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { logOut } from '../util';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Title from '../text/title';

export default function RegularsCountChart({ token, setToken }) {
    const [data, setData] = useState(false);
    let navigate = useNavigate()
    const theme = useTheme();

    useEffect(() => {
        let status;

        fetch('/api/stats/regulars-count?'
            // + new URLSearchParams({
            //     lookbackMonths: 3,
            //     numScans: 3,
            // })
            ,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                json.forEach(month => {
                    if (month.month === 1) {
                        month.chartName = month.year
                    } else {
                        // dayjs months are 0-indexed
                        month.chartName = dayjs().month(month.month - 1).format('MMM')
                    }

                    // timePeriod.chartName = dayjs(timePeriod._id).format('MMM')
                })

                setData(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <React.Fragment>
            <Title>Number of Regulars</Title>
            <ResponsiveContainer height={'85%'}>
                <LineChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        // left: 24,
                    }}
                >
                    <XAxis
                        dataKey="chartName"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                        tickFormatter={tick => `${tick}`}
                        domain={[0, dataMax => Math.max(dataMax, 4)]}
                    >
                    </YAxis>
                    <Line
                        isAnimationActive={true}
                        type="monotone"
                        dataKey="numberOfRegulars"
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                    <Tooltip contentStyle={{backgroundColor: theme.palette.background.paper}} />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}