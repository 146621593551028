exports.scanUrlHost = window.location.origin.includes('local') ?
    window.location.origin.replace('local.fidomap.com', 'localhost').replace('3001', '8000') :
    window.location.origin

exports.logOut = function ({ navigate, setToken }) {
    localStorage.removeItem('token');
    setToken(null);
    navigate("/sign-in");
}

exports.roundIfInt = function (num, numDecimalPlaces = 2) {
    if (isNaN(num)) {
        return num
    }
    if((num | 0) === num)
        return num
    let factor = 10 ** numDecimalPlaces
    return Math.round((num + Number.EPSILON) * factor) / factor
}

exports.downloadObjectAsCSV = function(exportObj, exportName) {
    var dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(exportObj);
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".csv");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}