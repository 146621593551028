import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Title from "./../text/title";
import { Button, ListItemButton, ListItemIcon, ListItemText, List, Tooltip, Alert, AlertTitle } from "@mui/material";
import { Add, Forward, LocalOffer } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import AttributeSelectorMenu from "../menus/attribute-selector-menu";
import AttributeValueSelectorMenu from "../menus/attribute-value-selector-menu";
import SimpleTextDialog from "../dialogs/simple-text-dialog";
import CreateAttributeValueDialog from "../dialogs/create-attribute-value-dialog";

const excludeForbiddenAttributes = (attributes, forbiddenAttributes) => {
    if (attributes && forbiddenAttributes)
        return attributes.filter(a => !forbiddenAttributes[a._id])
    return attributes
}

const getScheduleData = (existingSchedules, id) => {
    if (!existingSchedules || !id) {
        return null
    }
    return existingSchedules.find(s => s._id === id)
}

const getUpdatedSchedules = (existingSchedules, id, updatedSchedule) => {
    let schedulesCopy = JSON.parse(JSON.stringify(existingSchedules))
    let indexToUpdate = schedulesCopy.findIndex(s => s._id === id)
    schedulesCopy[indexToUpdate] = updatedSchedule
    return schedulesCopy
}

export default function ScheduleDetails({ token, setToken, schedules, setSchedules, scheduleId, forbidden, setForbidden, attributes, attributeValues, setAttributeValues }) {
    // const [getScheduleData(schedules, scheduleId), setScheduleData] = useState(null);
    const [urlDialogOpen, setUrlDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [attributeSelectorOpen, setAttributeSelectorOpen] = useState(false);
    const [selectedAttributeValue, setSelectedAttributeValue] = useState(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    let navigate = useNavigate()

    // Update filter when new attribute values are selected
    // Seems to be setting values for the attribute that are values of other attributes
    useEffect(() => {
        if (!selectedAttribute || selectedAttributeValue === null) {
            return
        }
        setAttributeSelectorOpen(false)

        let scheduleCopy = JSON.parse(JSON.stringify(getScheduleData(schedules, scheduleId)))
        if (!scheduleCopy.attributes)
            scheduleCopy.attributes = {}
        if (selectedAttributeValue.length == 0) {
            delete scheduleCopy.attributes[selectedAttribute._id]
        } else {
            scheduleCopy.attributes[selectedAttribute._id] = selectedAttributeValue[0]._id
        }
        // return scheduleCopy
        if (!token)
            return logOut({ navigate, setToken })

        let status;
        // delete scheduleCopy.forbiddenAttributes

        fetch('/api/schedules/' + scheduleCopy._id, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(scheduleCopy)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    alert(json.message)
                    // alert(`You changes couldn't be saved. This might be because another overlapping schedule already sets one of the same attributes or already has a redirectUrl.`)
                    return console.log(json.message);
                }

                let newSchedules = getUpdatedSchedules(schedules, scheduleId, json.schedule)
                setSchedules(newSchedules)
                setForbidden(json.forbidden)
            })
            .catch(err => {
                console.log(err)
                return false;
            })

        setAnchorEl(null)
        setSelectedAttribute(null)
        setSelectedAttributeValue(null)
    }, [selectedAttributeValue])

    useEffect(() => {
        if (!token || !scheduleId)
            return

        let status;

        fetch('/api/schedules/' + scheduleId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }

                    return console.error(json.message);
                }

                // setScheduleData(json.schedule)
                let newSchedules = getUpdatedSchedules(schedules, scheduleId, json.schedule)
                setSchedules(newSchedules)

                setForbidden(json.forbidden)
            })
            .catch(err => {
                console.log(err)
            })
    }, [scheduleId, token])

    // Update selected values when selectedAttribute changes
    let getSelectedAttributeValues = (scheduleData) => {
        if (!selectedAttribute)
            return []
        return [{ _id: scheduleData.attributes[selectedAttribute._id] }] ?? []
    }

    const handleAddAttributeClick = (event) => {
        setSelectedAttribute(null)
        setAnchorEl(event.currentTarget);
        setAttributeSelectorOpen(true)
    };

    const openAttributeValueMenu = (event, attribute) => {
        setAttributeSelectorOpen(false)
        setSelectedAttribute(attributes.find(attr => attr._id === attribute[0]))
        setAnchorEl(event.currentTarget);
    };

    return (
        (!getScheduleData(schedules, scheduleId) || !scheduleId || !forbidden) ?
            "Select a schedule on the left"
            :
            <React.Fragment>
                <Title>{getScheduleData(schedules, scheduleId).name}</Title>
                <List>
                    <Tooltip placement="top-start" title={forbidden.fields['redirectUrl'] === true ? "An overlapping schedule already has a Redirect URL set." : ""}>
                        <span>
                            <ListItemButton
                                variant='rounded'
                                data-testid="schedule-redirect-url-button"
                                onClick={e => setUrlDialogOpen(true)}
                                sx={{
                                    overflow: 'auto',
                                    textOverflow: 'ellipsis !important',
                                }}
                                disabled={forbidden.fields['redirectUrl'] === true}
                            >
                                <ListItemIcon>
                                    <Forward />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        overflow: 'auto',
                                        textOverflow: 'ellipsis !important',
                                    }}
                                    primary={"Redirect URL"}
                                    secondary={getScheduleData(schedules, scheduleId)?.redirectUrl?.replace("https://", "").replace("www.", "") ?? "Click to set"}
                                />
                            </ListItemButton>
                        </span>
                    </Tooltip>
                    <SimpleTextDialog
                        token={token}
                        setToken={setToken}
                        open={urlDialogOpen}
                        setOpen={setUrlDialogOpen}
                        handleCloseExternal={e => e}
                        apiEndpoint={'/api/schedules/' + getScheduleData(schedules, scheduleId)._id}
                        value={getScheduleData(schedules, scheduleId)}
                        setValue={setSchedules}
                        dialogTitle={"Set Redirect URL"}
                        dbFieldname={"redirectUrl"}
                        inputLabel={"Redirect URL"}
                        entityId={scheduleId}
                        accountId={getScheduleData(schedules, scheduleId).accountId}
                        responseDataKey={'schedule'}
                    />
                    {getScheduleData(schedules, scheduleId).attributes && Object.entries(getScheduleData(schedules, scheduleId).attributes).map(attribute =>
                        <ListItemButton
                            variant='rounded'
                            key={attribute[0]}
                            // selected={selectedAttribute?._id === attribute[0]}
                            onClick={e => openAttributeValueMenu(e, attribute)}
                        >
                            <ListItemIcon>
                                <LocalOffer />
                            </ListItemIcon>
                            <ListItemText
                                primary={attributes?.find(attr => attr._id == attribute[0])?.name}
                                secondary={attributeValues?.find(value => value._id == attribute[1])?.name}
                            />
                        </ListItemButton>
                    )}
                </List>
                {(Object.keys(getScheduleData(schedules, scheduleId).attributes).length === 0) ?
                    <React.Fragment>
                        <Alert severity="warning">
                            <AlertTitle>This schedule has no attributes</AlertTitle>
                            Add some attributes so that this schedule tags scans with information about events or operational changes.
                        </Alert>
                    </React.Fragment> :
                    null
                }
                <Box sx={{ mt: 2 }} >
                    <Button
                        startIcon={<Add />}
                        onClick={handleAddAttributeClick}
                    >
                        Add Attribute
                    </Button>
                </Box>
                <AttributeSelectorMenu
                    anchorEl={anchorEl}
                    open={attributeSelectorOpen}
                    setOpen={setAttributeSelectorOpen}
                    attributes={excludeForbiddenAttributes(attributes, forbidden.attributes)}
                    attributeValues={attributeValues}
                    setSelectedAttribute={setSelectedAttribute}
                    setSelectedAttributeValues={setSelectedAttributeValue}
                    selectedAttributeIds={Object.keys(getScheduleData(schedules, scheduleId)?.attributes) ?? []}
                />
                <AttributeValueSelectorMenu
                    open={Boolean(selectedAttribute)}
                    setOpen={setSelectedAttribute}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    attributes={attributes}
                    attributeValues={attributeValues}
                    selectedAttribute={selectedAttribute}
                    setSelectedAttribute={setSelectedAttribute}
                    selectedAttributeValues={getSelectedAttributeValues(getScheduleData(schedules, scheduleId))}
                    setSelectedAttributeValues={setSelectedAttributeValue}
                    singleSelect={true}
                    setCreateDialogOpen={setCreateDialogOpen}
                />
                <CreateAttributeValueDialog
                    token={token}
                    setToken={setToken}
                    open={createDialogOpen}
                    setOpen={setCreateDialogOpen}
                    selectedAttributeId={selectedAttribute?._id}
                    setAttributeValueList={setAttributeValues}
                />
            </React.Fragment>

    )
}