
import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import QrCodesList from "../cards/qr-codes-list";
import QrAttributes from "../cards/qr-attributes";

const drawerWidth = 240;

export default function QrCodesV2({ token, setToken }) {
    const [qrCodes, setQrCodes] = useState(null);
    const [selectedQrCodeId, setSelectedQrCodeId] = useState(null);
    const [attributes, setAttributes] = useState(null);
    const [attributeValues, setAttributeValues] = useState(null);
    const [pageTemplates, setPageTemplates] = useState([]);
    let navigate = useNavigate()

    useEffect(() => {
        async function fetchAttributesAndValues() {
            if (!token)
                return logOut({ navigate, setToken })

            let attributePromise = fetch('/api/attributes', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
            let valuesPromise = fetch('/api/attribute-values', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            let pageTemplatesPromise = fetch('/api/page-templates', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            try {
                let [attributesResponse, valuesResponse, pageTemplatesResponse] = await Promise.all([attributePromise, valuesPromise, pageTemplatesPromise]);
                let [attributesJson, valuesJson, pageTemplatesJson] = await Promise.all([attributesResponse.json(), valuesResponse.json(), pageTemplatesResponse.json()]);
                if (attributesResponse.status !== 200 || valuesResponse.status !== 200 || pageTemplatesResponse.status !== 200) {
                    if (attributesResponse.status === 401 || valuesResponse.status === 401 || pageTemplatesResponse.status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return
                }
                setAttributes(attributesJson)
                setAttributeValues(valuesJson)
                setPageTemplates(pageTemplatesJson)
            } catch (err) {
                console.log(err)
            }
        }
        if (attributes === null || attributeValues === null || pageTemplates === null)
            fetchAttributesAndValues();
    }, [])

    useEffect(() => {
        if (!token || !selectedQrCodeId)
            return

        let status;

        fetch('/api/qr-codes/' + selectedQrCodeId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }

                setQrCode(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [selectedQrCodeId, token])



    const setQrCode = qrCode => {
        let copy = JSON.parse(JSON.stringify(qrCodes))
        let index = copy.findIndex(i => i._id === selectedQrCodeId)

        if(typeof qrCode === 'function') {
            copy[index] = qrCode(copy[index])
        } else {
            copy[index] = qrCode
        }

        setQrCodes(copy)
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                height: '100vh',
                overflow: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900]
            }}
        >
            <Container
                maxWidth="lg"
                sx={{ display: 'flex' }}
                className="content-root"
            >
                <Toolbar />
                <Grid container spacing={3} className="flex-section"
                    sx={{
                        flexWrap: 'nowrap',
                    }}
                >
                    <Grid item xs={12} md={6} lg={4} className={"flex-col-scroll"} >
                        <Paper sx={{
                            my: 4,
                            p: 2,
                            minHeight: 240,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <QrCodesList token={token} setToken={setToken} selectedQrCodeId={selectedQrCodeId} setSelectedQrCodeId={setSelectedQrCodeId} qrCodes={qrCodes} setQrCodes={setQrCodes} pageTemplates={pageTemplates} attributes={attributes} attributeValues={attributeValues} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={8} className={"flex-col-scroll"} >
                        <Paper
                            sx={{
                                my: 4,
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <QrAttributes token={token} attributes={attributes} qrCode={qrCodes?.find(i => i._id === selectedQrCodeId)} setQrCode={setQrCode} attributeValues={attributeValues} setAttributeValues={setAttributeValues} pageTemplates={pageTemplates} qrCodeId={selectedQrCodeId} qrCodes={qrCodes} setQrCodes={setQrCodes} />
                        </Paper>
                    </Grid>
                </Grid>
                {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
        </Box>
    )
}
