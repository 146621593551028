import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logOut, roundIfInt } from '../util';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Label, Tooltip, Legend } from 'recharts';
import Title from '../text/title';

export default function ReturnRateWinnersChart({ token, setToken, attributes, hideTitle }) {
    const [data, setData] = useState(false);
    let navigate = useNavigate()
    const theme = useTheme();

    useEffect(() => {
        let status;

        fetch('/api/stats/return-rate-winners?'
            // + new URLSearchParams({
            //     compareLoyalty: true
            // })
            ,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                let data = json.map(attributeValueReturnRate => (
                    {
                        name: `${attributeValueReturnRate.attributeName}: ${attributeValueReturnRate.attributeValueName}`,
                        "Return Rate": roundIfInt(attributeValueReturnRate.returnRate * 100, 1),
                        "New Guests": attributeValueReturnRate.newGuests
                    }
                ))

                setData(data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <React.Fragment>
            {hideTitle ?
                null :
                <Title>Return Rate Winners</Title>
            }
            <ResponsiveContainer height={hideTitle ? '100%' : '85%'}>
                <BarChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="name"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                        domain={['There aren\'t any scans with attributes yet']}
                    />
                    <YAxis
                        // stroke={theme.palette.text.secondary}
                        // style={theme.typography.body2}
                        // tickFormatter={tick => `${tick}`}
                        // domain={[0, 100]}
                        domain={[0, 'auto']}
                        yAxisId="left"
                        orientation="left"
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.secondary,
                                ...theme.typography.body1,
                            }}
                        >
                            Return Rate (%)
                        </Label>
                    </YAxis>
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        scale="log" 
                        domain={['auto', 'auto']}
                    >
                        <Label
                            angle={270}
                            position="right"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.secondary,
                                ...theme.typography.body1,
                            }}
                        >
                            # New Guests
                        </Label>
                    </YAxis>

                    <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} />
                    <Bar
                        yAxisId="left"
                        isAnimationActive={true}
                        // type="monotone"
                        dataKey="Return Rate"
                        // stroke={theme.palette.primary.main}
                        fill={theme.palette.primary.main}
                    />
                    <Bar
                        yAxisId="right"
                        isAnimationActive={true}
                        // type="monotone"
                        dataKey="New Guests"
                        // stroke={theme.palette.primary.main}
                        fill={theme.palette.info.main}
                    />
                    <Legend />
                </BarChart>
                { /* horizontal version */ }
                {/* <BarChart
                    data={data}
                    layout="vertical" 
                    // barCategoryGap={1}
                    margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                >
                    <XAxis type="number" />
                    <YAxis 
                        type="category" 
                        width={150} 
                        padding={{ left: 20 }} 
                        dataKey="name" 
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body} 
                    />

                    <Bar
                        dataKey="Return Rate"
                        fill={theme.palette.primary.main}
                        isAnimationActive={true}
                        stackId="a"
                        // label={<CustomizedLabel />}
                    />
                    <Bar
                        dataKey="New Guests"
                        fill={theme.palette.secondary.main}
                        isAnimationActive={true}
                        stackId="a"
                        // label={<CustomizedLabel />}
                    />
                    <Legend />
                    <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} />

                </BarChart> */}
            </ResponsiveContainer>
        </React.Fragment>
    );
}