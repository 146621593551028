import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import { createSavedView } from '../network/saved-view';

export default function CreateSavedViewDialog({ token, setToken, open, setOpen, savedView, setState }) {
    const [nameInput, setNameInput] = useState('')
    let navigate = useNavigate()

    function handleClose() {
        setOpen(false)
    }

    const handleInputChange = (event) => {
        setNameInput(event.target.value);
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter')
            handleSubmit()
    }

    async function handleSubmit() {
        let newSavedView = {
            ...savedView,
            name: nameInput,
            viewType: "tableMap"
        }

        let newView = await createSavedView({ token, setToken, navigate, newSavedView })
        console.log(newView)

        if (newView) {
            setState(views => {
                return [...views, newView]
            })
        }
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth >
            <DialogTitle>Save View</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    What do you want to call this saved view?
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder='Main Taproom'
                    autoComplete='off'
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Save View</Button>
            </DialogActions>
        </Dialog>
    );
}
