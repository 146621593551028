import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import GuestContent from "../cards/guests";

const drawerWidth = 240;

export default function GuestsV2({ token, setToken }) {
    const [guests, setGuests] = useState(null);
    const [filter, setFilter] = useState({});
    let navigate = useNavigate()

    useEffect(() => {
        if (!token)
            return logOut({ navigate, setToken })

        let status;
        fetch('/api/guests', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(filter)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }
                setGuests(json)
            })
            .catch(err => {
                console.log(err)
            })
    }, [filter])

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                height: '100vh',
                overflow: 'wrap',
                textOverflow: 'ellipsis !important',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900]
            }}
        >
            <Container
                maxWidth="lg"
                sx={{ display: 'flex' }}
                className="content-root"
            >
                <Toolbar />
                <Grid container spacing={3} className="flex-section"
                    sx={{
                        flexWrap: 'nowrap',
                    }}
                >
                    <Grid item xs={12} md={8} className={"flex-col-scroll"}>
                        <Paper sx={{
                            my: 4,
                            p: 2,
                            minHeight: 240,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <GuestContent token={token} setToken={setToken} guests={guests} filter={filter} setFilter={setFilter} />
                        </Paper>
                    </Grid>
                </Grid>
                {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
        </Box>
    )
}