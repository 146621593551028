import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// libs
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TrendingUp, People, Event, FileUpload, PersonAdd, CropFree } from "@mui/icons-material";
import { logOut } from './util'
import InviteUserDialog from "./dialogs/invite-user-dialog";

const drawerWidth = 240;

let headerTitles = {
  "/guest-map": "Live Guest Map",
  "/qr-codes": "QR Codes",
  "/attributes": "Attributes",
  "/analytics": "Analytics",
  "/schedules": "Schedules",
  "/guests": "Guests",
}

function ResponsiveDrawer({ window, token, setToken }) {
  let navigate = useNavigate();
  let theme = useTheme();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const uploadFile = (file, token) => {
    const formData = new FormData();
    formData.append('report-file', file);
    return fetch('/api/integrations/report', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: formData
    })
  }

  const handleFileChange = (event) => {
    let file = event.clipboardData ? event.clipboardData.files[0] : event.target.files[0]
    event.target.value = null
    if (!file) return; // Don't do anything when user pastes text

    let uploadFilePromise = uploadFile(file, token)
    uploadFilePromise
      .then(res => {
        if (res.status === 200) {
          return res.json()
        } else {
          if (res.status === 400) {
            console.log('Oops! Files must be less than 100 Mb.')
          }
          if (res.status === 401) {
            console.log('You don\'t have permission to upload files here.')
            logOut({ navigate, setToken })
          }
          return Promise.reject(res);
        }
      })
      .then(result => {
        // need to use most recent version of state when updating optimistically added leaves
        console.log(`${result.rowCount} rows imported`)
      })
      .catch((error) => {
        console.log("upload failed")
      })
  }

  const drawer = (
    <div>
      <Toolbar >
        <img style={{marginLeft: "-4px"}} height="40px" src={'img/greenLogoSpace.png'}></img>
      </Toolbar>
      <Divider />
      <List>
        {[
          {
            name: 'Live Guest Map',
            icon: <MapIcon />,
            link: '/guest-map'
          },
          {
            name: 'Scans',
            icon: <CropFree />,
            link: '/scans'
          },
          {
            name: 'Analytics',
            icon: <TrendingUp />,
            link: '/analytics'
          },
          {
            name: 'Guests',
            icon: <People />,
            link: '/guests',
            divider: true
          },
          {
            name: 'Attributes',
            icon: <LocalOfferIcon />,
            link: '/attributes'
          },
          {
            name: 'Schedules',
            icon: <Event />,
            link: '/schedules'
          },
          {
            name: 'QR Codes',
            icon: <QrCodeIcon />,
            link: '/qr-codes'
          }
        ].map((item) => (
          <React.Fragment key={item.name}>
            <ListItem disablePadding onClick={handleDrawerToggle} selected={item.link === location.pathname} component={NavLink} to={item.link} sx={{ color: theme.palette.text.primary }}>
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
            {item.divider &&
              <Divider />
            }
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <List>
        <label htmlFor="import-file-input">
          <ListItem key={'import data'} disablePadding>
            <ListItemButton
              component="span"
            >
              <ListItemIcon>
                <FileUpload />
              </ListItemIcon>
              <ListItemText primary={'Import Toast Data'} />
            </ListItemButton>
          </ListItem>
        </label>
        <input onChange={handleFileChange} encType='multipart/form-data' hidden accept=".csv" id="import-file-input" name="report-file" type="file" />
        <ListItem key={'invite user'} disablePadding>
          <ListItemButton
            onClick={() => setInviteUserDialogOpen(true)}
          >
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            <ListItemText primary={'Invite Teammate'} />
          </ListItemButton>
        </ListItem>
        <InviteUserDialog
          token={token}
          setToken={setToken}
          open={inviteUserDialogOpen}
          setOpen={setInviteUserDialogOpen}
        />
        <ListItem key={'log out'} disablePadding>
          <ListItemButton
            onClick={() => logOut({ navigate, setToken })}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={'Log Out'} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {headerTitles[location.pathname]}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { lg: 'block',  xs: 'none'  },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
