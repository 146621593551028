import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import Title from "./../text/title";
import { Button, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, List, Stack } from "@mui/material";
import { Download, FiberManualRecord, MoreHoriz, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut, roundIfInt, downloadObjectAsCSV } from "../util";
import ScanFilters from "./scan-filters";
import Scan from "../items/scan";

dayjs.extend(relativeTime)

let getIconColor = visitCount =>
    visitCount >= 5 ?
        "success" :
        visitCount >= 2 ?
            "info" :
            "warning"

function makePrimaryText(scan) {
    let text = ''

    if (scan.guest?.firstName && scan.guest?.lastName) {
        text += `${scan.guest.firstName} ${scan.guest.lastName}`
    } else {
        text += 'An unrecognized guest'
    }

    text += ` scanned at ${scan.qrName}`

    return text
}

function makeSecondaryText(scan) {
    let text = dayjs(scan.createdAt).fromNow()

    // if(scan.guest?.firstName && scan.guest?.lastName) {
    //     text += `${scan.guest.firstName} ${scan.guest.lastName}`
    // }

    text += ' - '

    let piecesOfInfo = []

    if (scan.guest.emails?.length > 0) {
        piecesOfInfo.push(scan.guest.emails[0])
    }

    if (scan.guest?.visitCount) {
        piecesOfInfo.push(`${scan.guest.visitCount - 1} previous ${(scan.guest.visitCount != 2) ? 'visits' : 'visit'}`)
    }

    if (scan.guest.avgSpend) {
        piecesOfInfo.push(`, avg. spend ${roundIfInt(scan.guest?.avgSpend)}`)
    }

    return text + piecesOfInfo.join(', ')
}

export default function Scans({ token, setToken, scans, filter, setFilter }) {
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);

    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data)
            setAnchorData(data);
    };

    return (
        <React.Fragment>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <Title>Scans</Title>
            </Stack>
            <ScanFilters
                token={token}
                filter={filter}
                setFilter={setFilter}
            />
            <List>
                {scans?.map(scan =>
                    <Scan
                        scan={scan}
                        hoveredItemId={hoveredItemId}
                        setHoveredItemId={setHoveredItemId}
                        setAnchorEl={setAnchorEl}
                        setAnchorData={setAnchorData}
                    />
                )}
            </List>
        </React.Fragment>
    )
}