import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'

export default function SimpleTextDialog({
    token,
    setToken,
    open,
    setOpen,
    handleCloseExternal,
    apiEndpoint,
    value,
    setValue,
    dialogTitle,
    dbFieldname,
    inputLabel,
    placeholderText,
    entityId,
    accountId,
    responseDataKey // Helps get just a specific key out of the response. Schedule responses, for example, contain a forbidden key and a schedule key
}) {
    const [input, setInput] = useState(value ? value[dbFieldname] : '')
    let navigate = useNavigate()

    function handleClose(event) {
        setOpen(false)
        handleCloseExternal();
        // setInput("")
    }

    const handleInputChange = (event) => {
        setInput(event.target.value);
        event.preventDefault();
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSubmit(event)
            event.preventDefault();
        }
    }

    useEffect(() => {
        if (value)
            setInput(value[dbFieldname] ?? '')
    }, [value])

    function handleSubmit(event) {
        let status;

        let body = {
            ...value,
            [dbFieldname]: input === '' ? null : input,
            accountId: accountId,
            _id: entityId
        }

        fetch(apiEndpoint, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {

                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    if (value)
                        setInput(value[dbFieldname] ?? '')
                    return alert(json.message)
                }

                setValue(value => {
                    let newValue = JSON.parse(JSON.stringify(value))
                    if (newValue.length) {
                        let index
                        if (responseDataKey) {
                            index = newValue.findIndex(i => i._id === json[responseDataKey]._id)
                        } else {
                            index = newValue.findIndex(i => i._id === json._id)
                        }

                        if (index !== -1) {
                            if (responseDataKey) {
                                newValue[index] = json[responseDataKey]
                            } else {
                                newValue[index] = json
                            }
                        }
                        return newValue
                    } else {
                        if (responseDataKey) {
                            return { ...value, ...json[responseDataKey] };
                        }
                        return { ...value, ...json };
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
        handleClose()
        event.preventDefault();
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    value={input}
                    margin="dense"
                    id={inputLabel}
                    label={inputLabel}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder={placeholderText}
                    autoComplete='off'
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
