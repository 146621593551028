import React, { useState, useEffect } from "react";
import Title from "./../text/title";
import TableBarIcon from '@mui/icons-material/TableBar';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { Box, Button, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import { Add, MoreHoriz } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import CreateAttributeValueDialog from "../dialogs/create-attribute-value-dialog";
import AttributeValueContextMenu from "../menus/attribute-value-context-menu";
import ScanCountByAttributeChart from "./scan-count-by-attribute-chart";

export default function AttributesValues({ token, setToken, selectedAttributeId, setSelectedAttributeId, attributeList, setAttributeList }) {
    const [attributeValueList, setAttributeValueList] = useState([]);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);
    let navigate = useNavigate()

    useEffect(() => {
        if (!token || !selectedAttributeId)
            return
        let status;

        fetch('/api/attribute-values/' + selectedAttributeId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                    return console.error(json.message);
                }
                setAttributeValueList(json);
            })
            .catch(err => {
                console.log(err)
            })
    }, [token, selectedAttributeId])

    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data)
            setAnchorData(data);
    };

    return (
        <React.Fragment>
            <Title>{attributeList?.find(i => i._id === selectedAttributeId)?.name + ' ' ?? ''}Values</Title>
            <Box
                sx={{
                    width: '100%',
                    height: 140
                }}
            >
                <ScanCountByAttributeChart
                    token={token}
                    setToken={setToken}
                    attributeId={selectedAttributeId}
                    attributes={attributeList}
                    hideTitle
                />
            </Box>
            <List>
                {attributeValueList?.map(item =>
                    <ListItem
                        key={item._id}
                        onMouseOver={() => setHoveredItemId(item._id)}
                        onMouseOut={() => setHoveredItemId(null)}
                        secondaryAction={
                            <IconButton
                                data-testid="attribute value context menu icon"
                                onClick={e => handleContextMenuClick(e, item)}
                                sx={{ display: (hoveredItemId === item._id || anchorData?._id === item._id) ? '' : 'none' }}
                                edge="end"
                                aria-label="more options"
                                value={JSON.stringify(item)}
                            >
                                <MoreHoriz />
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            variant='rounded'
                            selected={selectedAttributeId === item._id}
                            onClick={e => setSelectedAttributeId(item._id)}
                        >
                            {/* <ListItemIcon>
                                {item.attributes?.tableSize == 4 ?
                                    <TableRestaurantIcon /> :
                                    <TableBarIcon />
                                }
                            </ListItemIcon> */}
                            <ListItemText
                                primary={item.name}
                            // secondary={!item.attributes ? "No attributes" : Object.keys(item.attributes).length + " attributes"}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <AttributeValueContextMenu token={token} setToken={setToken} anchorEl={anchorEl} setAnchorEl={setAnchorEl} anchorData={anchorData} setAnchorData={setAnchorData} setAttributeValueList={setAttributeValueList} />
            {!selectedAttributeId && "Please select an attribute"}
            {selectedAttributeId &&
                <Box sx={{ mt: 2 }} >
                    <Button startIcon={<Add />} onClick={(event) => {
                        setCreateDialogOpen(true)
                        event.preventDefault();
                    }}
                    >
                        Create Value
                    </Button>
                </Box>
            }
            <CreateAttributeValueDialog token={token} setToken={setToken} open={createDialogOpen} setOpen={setCreateDialogOpen} selectedAttributeId={selectedAttributeId} setAttributeValueList={setAttributeValueList} />
        </React.Fragment>
    )
}