import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logOut, roundIfInt } from '../util';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Label, Tooltip, Legend } from 'recharts';
import Title from '../text/title';

export default function RevenueWinnersChart({ token, setToken, attributes, hideTitle }) {
    const [data, setData] = useState(false);
    let navigate = useNavigate()
    const theme = useTheme();

    useEffect(() => {
        let status;

        fetch('/api/stats/revenue-winners?'
            // + new URLSearchParams({
            //     compareLoyalty: true
            // })
            ,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        return logOut({ navigate, setToken })
                    }
                }

                let data = json.map(attributeValueRevenueInfo => (
                    {
                        name: `${attributeValueRevenueInfo.attributeName}: ${attributeValueRevenueInfo.attributeValueName}`,
                        "Day-of Revenue": attributeValueRevenueInfo.dayOfRevenue,
                        "Returning Revenue": attributeValueRevenueInfo.futureRevenue
                    }
                ))

                setData(data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <React.Fragment>
            {hideTitle ?
                null :
                <Title>Revenue Per Day</Title>
            }
            <ResponsiveContainer height={hideTitle ? '100%' : '85%'}>
                <BarChart
                    data={data}
                    layout="vertical"
                    margin={{ top: 0, right: 50, left: 10, bottom: 0 }}
                >
                    <XAxis
                        type="number"
                        // scale="log" 
                        // domain={['auto', 'auto']}
                        tickFormatter={tick => `$${tick}`}
                    />
                    <YAxis
                        type="category"
                        width={150}
                        padding={{ left: 20 }}
                        dataKey="name"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body}
                    />
                    <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} />

                    <Bar
                        dataKey="Day-of Revenue"
                        fill={theme.palette.primary.main}
                        isAnimationActive={true}
                        stackId="a"
                    />
                    <Bar
                        dataKey="Returning Revenue"
                        fill={theme.palette.info.main}
                        isAnimationActive={true}
                        stackId="a"
                    />
                    <Legend />
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}