import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Divider, ListItem, ListItemIcon } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import { Add } from '@mui/icons-material';

export default function QrAttributeSelectorMenu({ token, setToken, open, setOpen, attributes, attributeValues, anchorEl, setAnchorEl, qrCode, selectedAttribute, setSelectedAttribute, setQrCode, setCreateDialogOpen }) {
    // const [selectedAttribute, setSelectedAttribute] = useState(null);
    let navigate = useNavigate()

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedAttribute(null)
        setOpen(false)
    };

    const selectAttribute = (attribute) => {
        setSelectedAttribute(attribute)
    };

    const selectAttributeValue = (value) => {
        if (!token)
            return logOut({ navigate, setToken })

        let newAttribute = { [selectedAttribute._id]: value._id }
        let newQrCodeAttributes = { ...qrCode.attributes, ...newAttribute }
        let newQrCode = JSON.parse(JSON.stringify(qrCode))
        newQrCode.attributes = newQrCodeAttributes;

        let status;

        fetch('/api/qr-codes/' + qrCode._id, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(newQrCode)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }
                setQrCode(json);
                handleClose()
            })
            .catch(err => {
                console.log(err)
            })
    };

    const removeAttribute = (e, selectedAttribute) => {
        if (!token)
            return logOut({ navigate, setToken })

        let newQrCodeAttributes = { ...qrCode.attributes }
        delete newQrCodeAttributes[selectedAttribute._id];
        let newQrCode = JSON.parse(JSON.stringify(qrCode))
        newQrCode.attributes = newQrCodeAttributes;

        let status;

        fetch('/api/qr-codes/' + qrCode._id, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(newQrCode)
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if (status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }
                setQrCode(json);
                handleClose()
            })
            .catch(err => {
                console.log(err)
            })
    };

    return (
        <Menu
            id="attribute-selector-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && open}
            onClose={handleClose}
        > {[
            (!selectedAttribute && attributes && attributes
                .filter(attr => !qrCode.attributes || !qrCode.attributes[attr._id])
                .map(attribute =>
                    <MenuItem
                        key={attribute._id}
                        onClick={e => selectAttribute(attribute)}
                    >
                        {attribute.name}
                    </MenuItem>
                )
            ),
            (selectedAttribute ?
                setCreateDialogOpen ?
                    <Box key="create new value">
                        <MenuItem onClick={(event) => {
                            setCreateDialogOpen(true)
                            event.preventDefault();
                        }}
                        >
                            <ListItemIcon>
                                <Add fontSize="small" />
                            </ListItemIcon>
                            New {selectedAttribute?.name}
                        </MenuItem>
                        <Divider />
                    </Box>
                    :
                    <ListItem key="select prompt" tabIndex={0} disabled >Select {selectedAttribute.name}</ListItem>
                :
                null
            ),
            (selectedAttribute && attributeValues
                // only show values of the selected attribute
                .filter(value => value.attributeId === selectedAttribute._id)
                .map(value =>
                    <MenuItem
                        key={value._id}
                        onClick={e => selectAttributeValue(value)}
                        selected={qrCode.attributes?.[selectedAttribute._id] === value._id}
                    >
                        {value.name}
                    </MenuItem>
                )
            ),
            (selectedAttribute && qrCode.attributes?.[selectedAttribute._id] && attributes ?
                <Box key="remove filter">
                    <Divider />
                    <MenuItem
                        key="remove attribute"
                        onClick={e => removeAttribute(e, selectedAttribute)}
                        sx={{ color: "firebrick" }}
                    >
                        Remove attribute
                    </MenuItem>
                </Box>
                : null
            ),
            (attributes && qrCode.attributes?.length === attributes?.length &&
                <MenuItem key="no more" disabled>No more attributes to add</MenuItem>
            )
        ]}
        </Menu>
    );
}
