import React, { useState } from "react";
import Box from '@mui/material/Box';
import Title from "./../text/title";
import { Button, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import { Add, LocalOffer, Forward, ContentCopy, Smartphone, PhoneIphone, Preview, Aod } from "@mui/icons-material";
import { scanUrlHost, logOut } from './../util'
import QrAttributeSelectorMenu from "../menus/qr-attribute-selector-menu";
import SimpleTextDialog from "../dialogs/simple-text-dialog";
import PageTemplateSelectorMenu from "../menus/page-template-selector-menu";
import CreateAttributeValueDialog from "../dialogs/create-attribute-value-dialog";

export default function QrAttributes({ token, setToken, qrCodeId, qrCode, setQrCode, attributes, attributeValues, setAttributeValues, pageTemplates, qrCodes, setQrCodes }) {
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageTemplateSelectorOpen, setPageTemplateSelectorOpen] = useState(false);
    const [urlDialogOpen, setUrlDialogOpen] = useState(false);
    const [attributeSelectorMenuOpen, setAttributeSelectorMenuOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const openPageTemplateSelectorMenu = (event) => {
        setPageTemplateSelectorOpen(true)
        setAnchorEl(event.currentTarget);
    };

    const openAttributeSelectorMenu = (event, attribute) => {
        if (attribute) {
            setSelectedAttribute(attributes.find(attr => attr._id === attribute[0]))
        }
        setAnchorEl(event.currentTarget);
        setAttributeSelectorMenuOpen(true)
    };

    const copyToClipboard = content => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(content);
            setCopied(true);
            setTimeout(() => setCopied(false), 700);
        }
    }

    function setSelectedPageTemplateId(pageTemplateId) {
        setQrCode(qrCode => {
            let newQrCode = JSON.parse(JSON.stringify(qrCode))
            newQrCode.pageTemplateId = pageTemplateId
            return newQrCode
        })
    }

    return (
        !qrCode || !qrCodeId ?
            "Select a Qr Code on the left"
            :
            <React.Fragment>
                <Title>{qrCode.name}</Title>
                <List>
                    <ListItemButton variant='rounded'
                        onClick={e => copyToClipboard(`${scanUrlHost}/api/scan-menu?qrId=${qrCode._id}`)}
                        data-testid='qr-code-url'
                        data-qr-url={`${scanUrlHost}/api/scan-menu?qrId=${qrCode._id}`}
                        sx={{
                            overflow: 'auto',
                            textOverflow: 'ellipsis !important',
                        }}
                    >
                        <ListItemIcon>
                            <ContentCopy />
                        </ListItemIcon>
                        <ListItemText
                            primary={"QR Code Url"}
                            secondary={qrCode.redirectUrl ? copied ? 'Copied' : 'Click to copy' : "Set a redirect URL below"}
                        />
                    </ListItemButton>
                    <ListItemButton variant='rounded'
                        onClick={openPageTemplateSelectorMenu}
                        sx={{
                            overflow: 'auto',
                            textOverflow: 'ellipsis !important',
                        }}
                    >
                        <ListItemIcon>
                            <Aod />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Landing Page"}
                            secondary={pageTemplates.find(t => qrCode.pageTemplateId === t._id)?.name ?? "None (instant redirect)"}
                        />
                    </ListItemButton>
                    <PageTemplateSelectorMenu
                        token={token}
                        setToken={setToken}
                        pageTemplates={pageTemplates}
                        open={pageTemplateSelectorOpen}
                        setOpen={setPageTemplateSelectorOpen}
                        anchorEl={anchorEl}
                        selectedPageTemplateId={qrCode.pageTemplateId}
                        setSelectedPageTemplateId={setSelectedPageTemplateId}
                        entityToUpdate={qrCode}
                        updateEndpointUrl={'/api/qr-codes/'}
                    />
                    <ListItemButton variant='rounded'
                        onClick={e => setUrlDialogOpen(true)}
                        sx={{
                            overflow: 'auto',
                            textOverflow: 'ellipsis !important',
                        }}
                    >
                        <ListItemIcon>
                            <Forward />
                        </ListItemIcon>
                        <ListItemText
                            sx={{
                                overflow: 'auto',
                                textOverflow: 'ellipsis !important',
                            }}
                            primary={"Redirect URL"}
                            secondary={qrCode.redirectUrl?.replace("https://", "").replace("www.", "") ?? "Click to set"}
                        />
                    </ListItemButton>
                    <SimpleTextDialog
                        token={token}
                        setToken={setToken}
                        open={urlDialogOpen}
                        setOpen={setUrlDialogOpen}
                        handleCloseExternal={e => e}
                        apiEndpoint={'/api/qr-codes/' + qrCode._id}
                        value={qrCode}
                        setValue={setQrCode}
                        dialogTitle={"Set Redirect URL"}
                        dbFieldname={"redirectUrl"}
                        inputLabel={"Redirect URL"}
                        entityId={qrCode._id}
                        accountId={qrCode.accountId}
                    />
                    {qrCode.attributes && Object.entries(qrCode.attributes).map(attribute =>
                        <ListItemButton
                            variant='rounded'
                            key={attribute[0]}
                            // selected={selectedAttribute?._id === attribute[0]}
                            onClick={e => openAttributeSelectorMenu(e, attribute)}
                        >
                            <ListItemIcon>
                                <LocalOffer />
                            </ListItemIcon>
                            <ListItemText
                                primary={attributes?.find(attr => attr._id == attribute[0])?.name}
                                secondary={attributeValues?.find(value => value._id == attribute[1])?.name}
                            />
                        </ListItemButton>
                    )}
                </List>
                <Box sx={{ mt: 2 }} >
                    <Button
                        startIcon={<Add />}
                        onClick={openAttributeSelectorMenu}
                    >
                        Add Attribute
                    </Button>
                </Box>
                <QrAttributeSelectorMenu
                    selectedAttribute={selectedAttribute}
                    setSelectedAttribute={setSelectedAttribute}
                    token={token}
                    setToken={setToken}
                    attributes={attributes}
                    attributeValues={attributeValues}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    qrCode={qrCode}
                    setQrCode={setQrCode}
                    open={attributeSelectorMenuOpen}
                    setOpen={setAttributeSelectorMenuOpen}
                    setCreateDialogOpen={setCreateDialogOpen}
                />
                <CreateAttributeValueDialog
                    token={token}
                    setToken={setToken}
                    open={createDialogOpen}
                    setOpen={setCreateDialogOpen}
                    selectedAttributeId={selectedAttribute?._id}
                    setAttributeValueList={setAttributeValues}
                />
            </React.Fragment>
    )
}