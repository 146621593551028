import React, { useState, useEffect } from "react";
import Title from "./../text/title";
import { Box, Button, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import { Add, DateRange, Event, MoreHoriz } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logOut } from './../util'
import ScheduleMenu from "../menus/schedule-menu";
import CreateScheduleDialog from "../dialogs/create-schedule-dialog";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import calendar from 'dayjs/plugin/calendar'

dayjs().format()
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(calendar)

let calendarConfig = {
    sameDay: '[Today]', // The same day ( Today at 2:30 AM )
    nextDay: '[Tomorrow]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[Yesterday]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[Last] dddd', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'MMM D, YYYY' // Everything else ( 17/10/2011 )
}

export default function Schedules({ token, setToken, schedules, setSchedules, selectedScheduleId, setSelectedScheduleId, forbidden, setForbidden }) {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);
    let navigate = useNavigate()

    useEffect(() => {
        if(!anchorEl)
        setAnchorData(null)
    },[anchorEl])

    useEffect(() => {
        if (!token)
            return logOut({ navigate, setToken })
            
        let status;

        fetch('/api/schedules', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                status = response.status;
                return response.json();
            })
            .then(json => {
                if (status !== 200) {
                    if(status === 401) {
                        logOut({ navigate, setToken })
                    }
                    return console.log(json.message);
                }
                setSchedules(json);
                setSelectedScheduleId(json[0]?._id)
            })
            .catch(err => {
                console.log(err)
            })
    }, [token])

    const handleContextMenuClick = (event, data) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget)
        if (data)
            setAnchorData(data);
    };

    let multipleDays = (startDate, endDate) => {
        return dayjs(endDate).diff(dayjs(startDate), 'day') >= 1
    }

    let minToTime = min => {
        let hours = Math.floor(min / 60)
        let leadingHrZero = hours < 10 ? "0" : ""
        let minutes = min % 60
        let leadingMinZero = minutes < 10 ? "0" : ""
        return "" + leadingHrZero + hours + ":" + leadingMinZero + minutes
    }

    let makeSecondaryText = ({ startDate, endDate, timeZone, startTime, endTime }) => {
        let string = ""
        if (multipleDays(startDate, endDate))
            string += dayjs(startDate).tz(timeZone).tz(dayjs.tz.guess(), true).calendar(null, calendarConfig) + ' - ' + dayjs(endDate).tz(timeZone).tz(dayjs.tz.guess(), true).calendar(null, calendarConfig)
        else {

            string += dayjs(startDate).tz(timeZone).tz(dayjs.tz.guess(), true).calendar(null, calendarConfig)
        }
        if (startTime && endTime) {
            string += ", " + minToTime(startTime) + " - " + minToTime(endTime)
        }
        return string
    }

    return (
        <React.Fragment>
            <Title>Schedules</Title>
            <List>
                {schedules?.map(item =>
                    <ListItem
                        key={item._id}
                        onMouseOver={() => setHoveredItemId(item._id)}
                        onMouseOut={() => setHoveredItemId(null)}
                        secondaryAction={
                            <IconButton
                                onClick={e => handleContextMenuClick(e, item)}
                                sx={{ display: (hoveredItemId === item._id || anchorData?._id === item._id) ? '' : 'none' }}
                                edge="end"
                                aria-label="more options"
                                value={JSON.stringify(item)}
                            >
                                <MoreHoriz />
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            variant='rounded'
                            selected={selectedScheduleId === item._id}
                            onClick={e => setSelectedScheduleId(item._id)}
                        >
                            <ListItemIcon>
                                {multipleDays(item.startDate, item.endDate) ?
                                    <DateRange /> :
                                    <Event />
                                }
                            </ListItemIcon>
                            <ListItemText
                                // primary={item._id}
                                primary={item.name}
                                secondary={makeSecondaryText(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <ScheduleMenu token={token} setToken={setToken} anchorEl={anchorEl} setAnchorEl={setAnchorEl} anchorData={anchorData} setAnchorData={setAnchorData} setSelectedScheduleId={setSelectedScheduleId} setSchedules={setSchedules} setCreateDialogOpen={setCreateDialogOpen} />
            {!schedules && "Loading"}
            <Box sx={{ mt: 2 }} >
                <Button startIcon={<Add />} onClick={() => setCreateDialogOpen(true)}>
                    Create Schedule
                </Button>
            </Box>
            {createDialogOpen &&
                <CreateScheduleDialog token={token} setToken={setToken} open={createDialogOpen} onClose={() => setAnchorEl(null)} setOpen={setCreateDialogOpen} setSchedules={setSchedules} selectedSchedule={anchorData} setForbidden={setForbidden} setAnchorEl={setAnchorEl} />
            }
        </React.Fragment>
    )
}